// @ts-nocheck
import React, {
  forwardRef,
  useEffect,
  useRef,
  useState
} from "react";
import ReactSelectInput from "react-select";
import { colors } from "../config/theme";
import { toQuery } from "../helpers/general";
import { useCookie } from "../hooks/useCookie";
import apiService from "../service/apiService";

const defaultFormatDate = elem => elem;
const OPTIONS_TO_SHOW = 10;

function useRequestOptions({
  url,
  limit,
  query = "",
  formatData = defaultFormatDate
}) {
  const [loadedPages, setLoadedPages] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const hasNextPage = useRef(true);
  const isLoaded = useRef(false);
  const serachQueryRes = useRef(query);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useCookie("vToken");

  const fetchMoreResults = async (query: string) => {
    query = query !== undefined ? query : serachQueryRes.current;
    if (query !== serachQueryRes.current) {
      serachQueryRes.current = query;
      reloadResults(query);
    }
    const endPoint = `${url.path}${toQuery({
      page: "1",
      limit: `${limit}`,
      q: query
    })}`;
    if (hasNextPage.current) {
      try {
        setLoading(true);
        const response = await apiService.MakeGetRequest(endPoint, token);
        if (response) {
          const currentPage = response?.meta?.currentPage;
          const fetchedData = response?.items ?? response;
          if (currentPage && loadedPages.indexOf(currentPage) === -1) {
            setLoadedPages(current => [...current, currentPage]);
            setResults(currentResults => [...currentResults, ...fetchedData]);
            if (currentPage === response.meta.totalPages) {
              hasNextPage.current = false;
            } else {
              hasNextPage.current = true;
              setPageIndex(currentIndex => currentIndex + 1);
            }
          } else {
            hasNextPage.current = false;
            setResults(fetchedData);
          }
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const reloadResults = async (query: string) => {
    const endPoint = `${url.path}${toQuery({
      page: "1",
      limit: `${limit}`,
      q: query
    })}`;
    try {
      setLoading(true);
      const response = await apiService.MakeGetRequest(endPoint, token);
      if (response) {
        const currentPage = response?.meta?.currentPage;
        const fetchedData = response?.items ?? response;
        if (currentPage) {
          setLoadedPages([currentPage]);
          setResults(fetchedData);
          if (currentPage === response.meta.totalPages) {
            hasNextPage.current = false;
            setPageIndex(currentPage + 1);
          } else {
            hasNextPage.current = true;
            setPageIndex(currentPage + 1);
          }
        } else {
          hasNextPage.current = false;
          setResults(fetchedData);
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  

  useEffect(() => {
    isLoaded.current = false;
    setPageIndex(1);
    hasNextPage.current = true;
    setResults([]);
    isLoaded.current = false;
    setLoadedPages([]);
  }, [url]);

  useEffect(() => {
    if (!isLoaded.current) {
      fetchMoreResults();
    }
    return () => (isLoaded.current = true);
  }, []);

  return {
    data: results,
    isLoading: loading,
    getNextPage: fetchMoreResults,
    reloadResults
  };
}


export const FormSelectAPI = React.memo(
  forwardRef(
    (
      {
        url,
        formatOptions = defaultFormatDate,
        required = false,
        isSearchable = true,
        isLoading = false,
        defaultValue = "",
        query = "",
        onChange = null,
        placeholder = "Choisissez une option",
        ...rest
      }: any,
      ref
    ) => {
      const [defaultSelectValue, setDefaultSelectValue] = useState(
        defaultValue
      );

      useEffect(() => {
        setDefaultSelectValue(defaultValue);
        
      }, [defaultValue]);

      const {
        data: options,
        isLoading: loading,
        getNextPage: loadMenuOptions
      } = useRequestOptions({
        url,
        limit: OPTIONS_TO_SHOW,
        query,
        formatData: formatOptions
      });

      return (
        <ReactSelectInput
          onInputChange={query => {
            if (query !== undefined) {
              loadMenuOptions(query);
            }
          }}
          options={options}
          isLoading={loading || isLoading}
          isSearchable={isSearchable}
          noOptionsMessage={() => "Pas de données"}
          onMenuScrollToBottom={() => loadMenuOptions()}
          isMulti={false}
          maxMenuHeight="200px"
          minMenuHeight="200px"
          placeholder={placeholder}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: colors.primary
            }
          })}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              boxShadow: state.isFocused
                ? "rgb(129, 227, 249) 0rem 0rem 0rem 0.125rem"
                : "none"
            }),
            menu: base => ({
              ...base,
              fontFamily: "sans-serif",
              fontSize: "15px"
            }),
            menuPortal: base => ({
              ...base,
              zIndex: 1400,
              fontFamily: "sans-serif",
              fontSize: "15px"
            })
          }}
          menuPosition={"absolute"}
          menuPortalTarget={document.body}
          getOptionLabel={option => option?.label}
          getOptionValue={option => option?.id}
          isOptionSelected={(option, selectedValue) => {
            const isSelected = option?.id === selectedValue?.[0]?.id;
            return isSelected;
          }}
          {...rest}
          ref={ref}
          required={required}
          defaultValue={defaultSelectValue}
          onChange={val => {
            setDefaultSelectValue(val);
            onChange && onChange(val);
          }}
        />
      );
    }
  )
);
