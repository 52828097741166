import React, { useState } from "react";
import { DASHBOARD_ROUTES } from "../../../config/routes";
import { BriefcaseIcon } from "@heroicons/react/24/outline";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { UsersIcon } from "@heroicons/react/24/outline";
import { QrCodeIcon } from "@heroicons/react/24/outline";
import { TableCellsIcon } from "@heroicons/react/24/outline";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { useAuthContext } from "../../../context/auth";
import QrCodeModal from "../../../components/modals/QrCodeModal";
import ModifyAdminPasswordModal from "../../../components/modals/ModifyAdminPasswordModal";
import { ClockIcon } from "@heroicons/react/24/outline";
import { OpeningHoursModal } from "../PlaceTable";

export default function PlaceAdminHeader() {
  const { user } = useAuthContext();
  const [showQrCode, setShowQrCode] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showOpeningHours, setShowOpeningHours] = useState(false);

  const placeId = user?.adminForPlace ? user?.adminForPlace?.id : "";

  const headerNavigation = [
    {
      key: "Facture",
      label: "Facture",
      icon: BanknotesIcon,
      href: DASHBOARD_ROUTES.places.invoice(placeId)
    },
    {
      key: "Paiements",
      label: "Paiements",
      icon: BanknotesIcon,
      href: DASHBOARD_ROUTES.payment.list({ place: placeId })
    },
    {
      key: "Services",
      label: "Services",
      icon: BriefcaseIcon,
      href: DASHBOARD_ROUTES.places.service(placeId)
    },
    {
      key: "Gallery",
      label: "Galerie",
      icon: PhotoIcon,
      href: DASHBOARD_ROUTES.places.gallery(placeId)
    },
    {
      key: "Reviews",
      label: "Commentaires",
      icon: ChatBubbleLeftIcon,
      href: DASHBOARD_ROUTES.places.reviews(placeId)
    },
    {
      key: "Inspectors",
      label: "Inspecteurs",
      icon: UsersIcon,
      href: DASHBOARD_ROUTES.places.inspectors(placeId)
    },
    {
      key: "code",
      label: "Qrcode",
      icon: QrCodeIcon,
      onClick: () => setShowQrCode(true)
    },
    {
      key: "Plan",
      label: "Plans de tables",
      icon: TableCellsIcon,
      href: DASHBOARD_ROUTES.places.floor_planner(placeId)
    },
    {
      key: "Opening hours",
      label: "Heures d'ouverture",
      icon: ClockIcon,
      onClick: () => setShowOpeningHours(true)
    }
  ];
  return (
    <header className="mb-3 bg-white place-admin-header">
      {headerNavigation.map(nav => {
        const children = (
          <>
            {<nav.icon className="w-4 h-4" />}
            <span className="text-sm text-black">{nav.label}</span>
          </>
        );

        return (
          <React.Fragment key={nav.key}>
            {nav?.href ? (
              <Link
                to={nav.href}
                className="d-flex align-items-center gap-2 border px-2 py-1 rounded-full"
              >
                {children}
              </Link>
            ) : (
              <button
                className="d-flex align-items-center gap-2 border px-2 py-1 rounded-full btn btn-sm"
                onClick={nav.onClick ? nav.onClick : undefined}
              >
                {children}
              </button>
            )}
          </React.Fragment>
        );
      })}
      <QrCodeModal
        visible={showQrCode}
        onClose={() => setShowQrCode(false)}
        slug={user?.adminForPlace?.id ?? ""}
        category={user?.adminForPlace?.category.slug ?? ""}
        name={user?.adminForPlace?.name ?? ""}
      />
      <ModifyAdminPasswordModal
        visible={showPasswordModal}
        onClose={() => setShowPasswordModal(false)}
        place={user?.adminForPlace!}
      />
      <OpeningHoursModal
        visible={showOpeningHours}
        onClose={() => setShowOpeningHours(false)}
        placeId={showOpeningHours ? user?.adminForPlace?.id : ""}
        slug={user?.adminForPlace?.slug}
      />
    </header>
  );
}
