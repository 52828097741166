import { IconTypes } from "./types";
export default function TicketIcon({
  width = "26",
  height = "6",
  ...rest
}: Partial<IconTypes>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 14"
      {...rest}
    >
      <path
        d="M15.6669 4.00003V1.66669C15.6669 1.23712 15.3187 0.888916 14.8891 0.888916H0.889106C0.459531 0.888916 0.111328 1.23712 0.111328 1.66669V4.00003C0.970423 4.00003 1.66688 4.69644 1.66688 5.55558C1.66688 6.41468 0.970423 7.11114 0.111328 7.11114V9.44447C0.111328 9.874 0.459531 10.2222 0.889106 10.2222H14.8891C15.3187 10.2222 15.6669 9.874 15.6669 9.44447V7.11114C14.8078 7.11114 14.1113 6.41468 14.1113 5.55558C14.1113 4.69644 14.8078 4.00003 15.6669 4.00003ZM4.38911 8.2778C4.38911 8.49276 4.21517 8.66669 4.00022 8.66669C3.78526 8.66669 3.61133 8.49276 3.61133 8.2778V7.50003C3.61133 7.28507 3.78526 7.11114 4.00022 7.11114C4.21517 7.11114 4.38911 7.28507 4.38911 7.50003V8.2778ZM4.38911 5.94447C4.38911 6.15943 4.21517 6.33336 4.00022 6.33336C3.78526 6.33336 3.61133 6.15943 3.61133 5.94447V5.16669C3.61133 4.95174 3.78526 4.7778 4.00022 4.7778C4.21517 4.7778 4.38911 4.95174 4.38911 5.16669V5.94447ZM4.38911 3.61114C4.38911 3.82609 4.21517 4.00003 4.00022 4.00003C3.78526 4.00003 3.61133 3.82609 3.61133 3.61114V2.83336C3.61133 2.61841 3.78526 2.44447 4.00022 2.44447C4.21517 2.44447 4.38911 2.61841 4.38911 2.83336V3.61114ZM11.778 7.50003H6.33355C6.1186 7.50003 5.94466 7.32609 5.94466 7.11114C5.94466 6.89618 6.1186 6.72225 6.33355 6.72225H11.778C11.9929 6.72225 12.1669 6.89618 12.1669 7.11114C12.1669 7.32609 11.9929 7.50003 11.778 7.50003ZM11.778 4.38892H6.33355C6.1186 4.38892 5.94466 4.21498 5.94466 4.00003C5.94466 3.78507 6.1186 3.61114 6.33355 3.61114H11.778C11.9929 3.61114 12.1669 3.78507 12.1669 4.00003C12.1669 4.21498 11.9929 4.38892 11.778 4.38892ZM15.6669 11.6345V12.5556C15.6669 12.9851 15.3187 13.3334 14.8891 13.3334H0.889106C0.459531 13.3334 0.111328 12.9851 0.111328 12.5556V11.6344C0.355807 11.7216 0.615048 11.7778 0.889106 11.7778H14.8891C15.1632 11.7778 15.4224 11.7216 15.6669 11.6345Z"
        fill="currentColor"
      />
    </svg>
  );
}
