// @ts-nocheck
import {
  CFormCheck,
  CFormInput,
  CFormSelect,
  CModal,
  CModalContent,
  CModalHeader,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem
} from "@coreui/react";
import { QrCodeIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import {
  ArrowTopRightOnSquareIcon,
  EllipsisHorizontalIcon,
  LockClosedIcon
} from "@heroicons/react/24/solid";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import DeleteIcon from "../../components/icons/DeleteIcon";
import EditIcon from "../../components/icons/EditIcon";
import PlusIcon from "../../components/icons/PlusIcon";
import TimeSelectInput from "../../components/inputs/TimeSelectInput";
import ModifyAdminPasswordModal from "../../components/modals/ModifyAdminPasswordModal";
import PromptModal from "../../components/modals/PromptModal";
import QrCodeModal from "../../components/modals/QrCodeModal";
import { DAYS_OF_THE_WEEK, DELETE_ACTIONS } from "../../config/constants";
import { colors } from "../../config/theme";
import { useAuth } from "../../hooks/useAuth";
import { useCookie } from "../../hooks/useCookie";
import useOnClickOutside from "../../hooks/useOutSideClick";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toDateInputValue } from "../../helpers/general";
import VisibleSvg from "../../components/icons/VisibleIcon";
import NotVisibleSvg from "../../components/icons/NotVisibleIcon";
import { DASHBOARD_ROUTES } from "../../config/routes";
import DotIcon from "../../components/icons/DotIcon";

const cleanOpeningHoursData = (originalData: any) => {
  const cleanedData = {
    exceptions: originalData?.exceptions ?? [],
    sunday: {},
    saturday: {},
    friday: {},
    thursday: {},
    wednesday: {},
    tuesday: {},
    monday: {}
  };

  // Transform days
  Object.keys(originalData).forEach(day => {
    if (day === "exceptions") return;

    const originalDay = originalData[day];
    const cleanedDay = {};

    if (originalDay.status === "custom") {
      cleanedDay.ranges = originalDay.ranges.filter(
        range => range.to !== "" && range.from !== ""
      );
    } else if (originalDay.status === "openAllDay") {
      cleanedDay.openAllDay = true;
    } else {
      cleanedDay.closed = true;
    }

    cleanedData[day] = cleanedDay;
  });

  return cleanedData;
};

const transformToFormValue = value => {
  if (value.closed) {
    return { status: "closed" };
  } else if (value.openAllDay) {
    return { status: "openAllDay" };
  } else {
    return {
      status: "custom",
      ranges: value.ranges ?? []
    };
  }
};

const getDayHoursType = dayHours => {
  if (dayHours.closed) {
    return "closed";
  } else if (dayHours.openAllDay) {
    return "openAllDay";
  } else {
    return "custom";
  }
};

const formattedExceptionDates = exceptionDates => {
  return exceptionDates.map(exception => {
    return {
      ...exception,
      date: toDateInputValue(exception.date)
    };
  });
};

const dayHours = yup.object({
  status: yup.string(),
  ranges: yup.array().when("status", {
    is: "custom",
    then: () =>
      yup
        .array()
        .notRequired()
        .of(
          yup.object().shape({
            from: yup.string(),
            to: yup.string()
          })
        )
  })
});

const exceptionDates = yup.array().of(
  yup.object({
    name: yup.string(),
    date: yup.date(),
    repeating: yup.string()
  })
);

const openingHoursSchema = yup.object({
  monday: dayHours,
  tuesday: dayHours,
  wednesday: dayHours,
  thursday: dayHours,
  friday: dayHours,
  saturday: dayHours,
  sunday: dayHours,
  exceptions: exceptionDates
});

interface ModalProps {
  visible: boolean;
  onClose: () => void;
  placeId?: string;
  slug?: string;
}

const WORK_DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday"];
const WEEKENDS = ["saturday", "sunday"];

type menuProps = {
  menu: File | undefined;
};

function Menu({ visible, onClose, placeId }: ModalProps & { placeId: string }) {
  const { token } = useCookie("vToken");
  const { toastSuccess, toastError } = useToast();

  const AddMenuMutation = useMutation((data: menuProps) => {
    return apiService.MakePutRequest(
      `places/${placeId}/menu`,
      data,
      token,
      true
    );
  });

  const createServiceHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const menu = formData.get("menu") as File;

    const data = {
      menu
    };

    const mutationOptions = {
      onSuccess: () => {
        toastSuccess(general.fr.message.menuAdded);
        onClose();
      },
      onError: () => {
        toastError(general.fr.message.operationFailed);
      }
    };

    AddMenuMutation.mutate(data, mutationOptions);
  };

  return (
    <CModal visible={visible} onClose={onClose} alignment="center">
      <CModalContent className="p-3">
        <h5 className="fs-5 my-4">Ajouter un menu</h5>
        <form onSubmit={createServiceHandler} className="row ">
          <div className="col">
            <label htmlFor="menu">Menu</label>
            <CFormInput
              required
              name="menu"
              id="menu"
              type="file"
              accept="application/pdf"
              className="w-100"
            />
          </div>

          <button className="btn-danger btn shadow-primary mt-4 d-flex align-items-center justify-content-center text-white">
            {AddMenuMutation.isLoading ? (
              <>
                <CSpinner size="sm" /> {general.fr.wait}
              </>
            ) : (
              "Enregistrer le menu"
            )}
          </button>
        </form>
      </CModalContent>
    </CModal>
  );
}

type DefaultOpeningHours = Omit<OpeningHours, "id">;
const defaultOpeningHours: DefaultOpeningHours = {
  monday: {
    closed: true,
    openAllDay: true,
    from: "",
    to: ""
  },
  tuesday: {
    closed: true,
    openAllDay: true,
    from: "",
    to: ""
  },
  wednesday: {
    closed: true,
    openAllDay: true,
    from: "",
    to: ""
  },
  thursday: {
    closed: true,
    openAllDay: true,
    from: "",
    to: ""
  },
  friday: {
    closed: true,
    openAllDay: true,
    from: "",
    to: ""
  },
  saturday: {
    closed: true,
    openAllDay: true,
    from: "",
    to: ""
  },
  sunday: {
    closed: true,
    openAllDay: true,
    from: "",
    to: ""
  }
};

export function OpeningHoursModal({
  visible,
  onClose,
  placeId,
  slug
}: ModalProps) {
  const [formLoading, setFormLoading] = useState(false);
  const [activeKey, setActiveKey] = useState(1);
  const { token } = useCookie("vToken");
  const { toastSuccess, toastError } = useToast();
  const queryClient = useQueryClient();
  const { isAdmin } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    setValue: setFormValue,
    getValues: getFormValue,
    watch: watchFormValue,
    reset: resetForm
  } = useForm({
    resolver: yupResolver(openingHoursSchema),
    defaultValues: {
      monday: {
        ranges: [{ from: "08:30", to: "22:30" }]
      },
      tuesday: {
        ranges: [{ from: "08:30", to: "22:30" }]
      },
      wednesday: {
        ranges: [{ from: "08:30", to: "22:30" }]
      },
      thursday: {
        ranges: [{ from: "08:30", to: "22:30" }]
      },
      friday: {
        ranges: [{ from: "08:30", to: "22:30" }]
      },
      saturday: {
        ranges: [{ from: "08:30", to: "22:30" }]
      },
      sunday: {
        ranges: [{ from: "08:30", to: "22:30" }]
      }
    }
  });

  const {
    fields: exceptionFields,
    append: appendException,
    remove: removeException,
    update: updateException
  } = useFieldArray({
    control: formControl,
    name: "exceptions"
  });

  useEffect(() => {
    if (visible) {
      apiService
        .MakeGetRequest(`places/${placeId}/openingHours`, token)
        .then(resp => {
          const formFields = Object.keys(openingHoursSchema.fields);
          Object.entries(resp).forEach(entry => {
            const [day, value] = entry;
            if (formFields.indexOf(day) !== -1) {
              if (day === "exceptions") {
                setFormValue("exceptions", formattedExceptionDates(value));
              } else {
                const dayHoursType = getDayHoursType(value);
                if (dayHoursType === "custom") {
                  setFormValue(day, transformToFormValue(value));
                  setFormValue(`${day}.ranges`, value.ranges);
                } else {
                  setFormValue(day, transformToFormValue(value));
                }
              }
            }
          });
        })
        .catch(err => {
          console.error(`!!!!!!! ${err}`);
          setFormValue("monday", { status: "closed" });
          setFormValue("tuesday", { status: "closed" });
          setFormValue("wednesday", { status: "closed" });
          setFormValue("thursday", { status: "closed" });
          setFormValue("friday", { status: "closed" });
          setFormValue("saturday", { status: "closed" });
          setFormValue("sunday", { status: "closed" });
        });
    }
  }, [visible, placeId, resetForm, setFormValue]);

  const openingHoursMutation = useMutation(
    (data: typeof defaultOpeningHours) => {
      return apiService.MakePutRequest("places/ours/openingHours", data, token);
    }
  );

  const adminOpeningHoursMutation = useMutation(
    (data: typeof defaultOpeningHours) => {
      return apiService.MakePostRequest(
        `places/${placeId}/openingHours`,
        data,
        token
      );
    }
  );

  const onSubmit = async (data: any) => {
    try {
      setFormLoading(true);
      const cleanedData = cleanOpeningHoursData(data);
      const mutationOptions = {
        onSuccess() {
          toastSuccess(general.fr.message.openingHrAdded);
          queryClient.invalidateQueries("getPlace");
          onClose();
        },
        onError() {
          toastError(general.fr.message.operationFailed);
        }
      };
      isAdmin
        ? adminOpeningHoursMutation.mutate(cleanedData, mutationOptions)
        : openingHoursMutation.mutate(cleanedData, mutationOptions);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const copyTo = (type: "workDays" | "weekends" | "all", value: any) => {
    if (type === "workDays") {
      WORK_DAYS.forEach(day => {
        setFormValue(day, value);
      });
    } else if (type === "weekends") {
      WEEKENDS.forEach(day => {
        setFormValue(day, value);
      });
    } else {
      // all
      [...WORK_DAYS, ...WEEKENDS].forEach(day => {
        setFormValue(day, value);
      });
    }
  };

  return (
    <CModal
      visible={visible && placeId ? true : false}
      onClose={() => {
        onClose();
        setActiveKey(1);
        resetForm();
      }}
      size="lg"
      backdrop="static"
    >
      <CModalHeader>
        <h5 className="fs-5">Ajouter les horaires d'ouverture</h5>
      </CModalHeader>
      <CModalContent className=" border-0 px-3 py-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <CNav variant="tabs" role="tablist">
            <CNavItem role="presentation">
              <CNavLink
                active={activeKey === 1}
                component="button"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected={activeKey === 1}
                onClick={() => setActiveKey(1)}
              >
                Horaires d'ouvertures
              </CNavLink>
            </CNavItem>
            <CNavItem role="presentation">
              <CNavLink
                active={activeKey === 2}
                component="button"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected={activeKey === 2}
                onClick={() => setActiveKey(2)}
              >
                Configurer les jours fériés
              </CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent>
            <CTabPane
              role="tabpanel"
              aria-labelledby="home-tab-pane"
              visible={activeKey === 1}
              className="pt-4"
            >
              <div className="row">
                <CAccordion alwaysOpen>
                  {DAYS_OF_THE_WEEK.map(day => (
                    <div key={day.en} className="col-12">
                      <DayTimeInput
                        day={day.fr}
                        dayEn={day.en}
                        copyTo={copyTo}
                        formControl={formControl}
                        formErrors={errors}
                        formSetValues={setFormValue}
                        formGetValues={getFormValue}
                        formWatchValues={watchFormValue}
                        formRegister={register}
                      />
                    </div>
                  ))}
                </CAccordion>
              </div>
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="profile-tab-pane"
              visible={activeKey === 2}
            >
              {exceptionFields.map((exception, index) => (
                <ExceptionDate
                  key={exception.id}
                  index={index}
                  exception={exception}
                  removeException={() => removeException(index)}
                  updateException={updateException}
                  formControl={formControl}
                  formErrors={errors}
                  formSetValues={setFormValue}
                  formGetValues={getFormValue}
                  formWatchValues={watchFormValue}
                  formRegister={register}
                />
              ))}
              <div className="d-flex justify-content-center mt-4">
                <button
                  type="button"
                  className="btn btn-primary btn-sm text-white"
                  onClick={() =>
                    appendException({ name: "", date: "", repeating: "" })
                  }
                >
                  Ajouter une date
                </button>
              </div>
            </CTabPane>
          </CTabContent>
          <button
            disabled={openingHoursMutation.isLoading}
            type="submit"
            className="mt-4 btn btn-danger shadow-primary w-100 p-2 d-flex align-items-center justify-content-center"
          >
            {openingHoursMutation.isLoading ? (
              <>
                <CSpinner size="sm" /> {general.fr.wait}
              </>
            ) : (
              "Enregistrer"
            )}
          </button>
        </form>
      </CModalContent>
    </CModal>
  );
}

function ExceptionDate({
  exception,
  index,
  removeException,
  updateException,
  formControl,
  formErrors,
  formSetValues,
  formGetValues,
  formWatchValues,
  formRegister
}) {
  return (
    <div className="d-flex justify-content-between border-bottom py-3">
      <div className="px-2" style={{ flex: 1 }}>
        <label htmlFor="name">Nom</label>
        <CFormInput
          name={`exceptions.${index}.name`}
          id={`exceptions.${index}.name`}
          {...formRegister(`exceptions.${index}.name`)}
        />
      </div>
      <div className="px-2" style={{ flex: 1 }}>
        <label htmlFor="name">Date</label>
        <CFormInput
          type="date"
          name={`exceptions.${index}.date`}
          {...formRegister(`exceptions.${index}.date`)}
        />
      </div>
      <div className="px-2" style={{ flex: 1 }}>
        <label htmlFor="name">Répétition annuelle</label>
        <CFormSelect
          className="mt-0"
          name={`exceptions.${index}.repeating`}
          {...formRegister(`exceptions.${index}.repeating`)}
        >
          <option selected value="">
            Choisissez une option
          </option>
          <option value="true">Oui</option>
          <option value="false">Non</option>
        </CFormSelect>
      </div>
      <div
        className="d-flex align-items-end justify-content-end"
        style={{ width: "45px" }}
      >
        <button
          type="button"
          className="btn btn-danger btn-sm"
          onClick={() => removeException()}
          style={{
            width: "41px",
            height: "41px"
          }}
        >
          <DeleteIcon width="18" height="18" fill="#ffffff" />
        </button>
      </div>
    </div>
  );
}

interface OpenHours {
  closed?: boolean;
  openAllDay?: boolean;
  from?: string;
  to?: string;
}
interface DayTimeInputProps {
  day: string;
  dayEn: string;
  copyTo: string;
  addOpeningHours: (day: string, value: OpenHours) => void;
  openingHours: OpenHours;
  formControl: any;
  formErrors: any;
  formSetValues: any;
  formGetValues: any;
  formWatchValues: any;
  formRegister: any;
}

function DayDisplayValue({ dayHours }) {
  if (dayHours.status === "closed") {
    return <div>Fermé</div>;
  } else if (dayHours.status === "openAllDay") {
    return <div>Ouvert toute la journée</div>;
  } else if (dayHours.status === "custom") {
    return (
      <div>
        {dayHours.ranges?.map((range, index) => (
          <div key={index}>
            {range.from} - {range.to}
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
}

function DayTimeInput({
  day,
  addOpeningHours,
  openingHours,
  dayEn,
  copyTo,
  formControl,
  formErrors,
  formSetValues,
  formGetValues,
  formWatchValues,
  formRegister
}: DayTimeInputProps) {
  const {
    fields: rangeFields,
    append: appendRange,
    remove: removeRange,
    update: updateRange
  } = useFieldArray({
    control: formControl,
    name: `${dayEn}.ranges`
  });

  const dayHours = formWatchValues(dayEn);
  const isCustomHours = dayHours.status === "custom";

  return (
    <CAccordionItem itemKey={dayEn}>
      <CAccordionHeader>
        <div className="w-full d-flex justify-content-between pe-2">
          <div>{day}</div>
          <div>
            <DayDisplayValue dayHours={dayHours} />
          </div>
        </div>
      </CAccordionHeader>
      <CAccordionBody>
        <div className="position-relative openingHour-type-wrapper">
          <div className="d-lg-flex align-items-center justify-content-between gap-4">
            <div className="d-lg-flex align-items-center gap-4">
              <CFormCheck
                type="radio"
                name={`${dayEn}-1-openingHr`}
                id={`${dayEn}-1-openingHr`}
                label="Fermé"
                value="closed"
                {...formRegister(`${dayEn}.status`)}
              />
              <CFormCheck
                type="radio"
                name={`${dayEn}-2-openingHr`}
                id={`${dayEn}-2-openingHr`}
                label="Ouvert toute la journée"
                value="openAllDay"
                {...formRegister(`${dayEn}.status`)}
              />
              <CFormCheck
                type="radio"
                name={`${dayEn}-3-openingHr`}
                id={`${dayEn}-3-openingHr`}
                label="Personnalisé"
                value="custom"
                {...formRegister(`${dayEn}.status`)}
              />
            </div>
            <div>
              <CDropdown direction="center">
                <CDropdownToggle
                  className="outline-none border-0 rounded-sm bg-transparent d-flex align-items-center gap-2"
                  // color="primary"
                >
                  <DotIcon fill="black" width="18" />
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem
                    type="button"
                    onClick={() => {
                      copyTo("workDays", formGetValues(dayEn));
                    }}
                  >
                    Copier vers les jours ouvrés
                  </CDropdownItem>
                  <CDropdownItem
                    type="button"
                    onClick={() => {
                      copyTo("weekends", formGetValues(dayEn));
                    }}
                  >
                    Copier vers les week-ends
                  </CDropdownItem>
                  <CDropdownItem
                    type="button"
                    onClick={() => {
                      copyTo("all", formGetValues(dayEn));
                    }}
                  >
                    Copier vers tous
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </div>
          </div>
          {isCustomHours && (
            <div>
              {rangeFields.map((range, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between gap-3 mt-2"
                >
                  <div className="w-50">
                    <Controller
                      name={`${dayEn}.ranges.${index}.from`}
                      control={formControl}
                      render={({ field }) => (
                        <TimeSelectInput
                          label="Heure d'ouverture"
                          selectedTime={field.value}
                          onChange={val => field.onChange(val)}
                        />
                      )}
                    />
                  </div>
                  <div className="w-50 d-flex flex-column align-items-end">
                    <Controller
                      name={`${dayEn}.ranges.${index}.to`}
                      control={formControl}
                      render={({ field }) => (
                        <TimeSelectInput
                          label="Heure de clôture"
                          selectedTime={field.value}
                          onChange={val => field.onChange(val)}
                        />
                      )}
                    />
                  </div>
                  <div
                    className="d-flex align-items-end justify-content-end"
                    style={{ width: "45px" }}
                  >
                    {index > 0 && (
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => removeRange(index)}
                        style={{
                          width: "41px",
                          height: "41px"
                        }}
                      >
                        <DeleteIcon width="18" height="18" fill="#ffffff" />
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <div className="mt-2 d-flex align-center justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => appendRange({ from: "", to: "" })}
                >
                  <PlusIcon width="18" height="18" fill="#ffffff" />
                </button>
              </div>
            </div>
          )}
        </div>
      </CAccordionBody>
    </CAccordionItem>
  );
}

function TableBodyRowContent({
  place,
  deletedList
}: {
  place: Place;
  deletedList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [MenuModal, setMenuModal] = useState(false);
  const [showOpeningHours, setShowOpeningHours] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => setShowDropdown(false));

  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();

  const deletePlace = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`places/${id}`, token);
  });

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: place.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: place.id });
    }
  }, [deletedList, isChecked, place.id]);

  const removePlace = () => {
    deletePlace.mutate(place.id, {
      onError(error) {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.placeDeleted);
        queryClient.invalidateQueries("getAllPlaces");
      }
    });
  };

  const { isAdmin } = useAuth();

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%"
      }}
    >
      <CTableDataCell className="px-3">
        <div className="d-flex align-items-center gap-3">
          {isAdmin && (
            <CFormCheck
              id="flexCheckDefault"
              label=""
              defaultChecked={isChecked}
              onChange={e => setIsChecked(e.target.checked)}
              color={colors.yellow}
            />
          )}
          <img
            src={place?.logoImage || place?.image}
            alt=""
            width={30}
            height={30}
            style={{ borderRadius: "100%" }}
          />

          <div>
            <Link
              to={`/places/${place?.id}`}
              className="fw-bold m-0 text-capitalize text-sm"
            >
              {place?.name}
            </Link>
            <p className="m-0 text-sm">{place?.city?.label}</p>
          </div>
        </div>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {place?.isVisible ? (
          <VisibleSvg className="fill-primary" />
        ) : (
          <NotVisibleSvg className="fill-red" />
        )}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">{place?.phone}</CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {place?.category?.label}
      </CTableDataCell>

      <CTableDataCell className="p-3">
        <ArrowTopRightOnSquareIcon
          className="me-1"
          width="20"
          height="20"
          onClick={() => setMenuModal(true)}
        />
      </CTableDataCell>

      <CTableDataCell
        className="p-3"
        onClick={() => {
          setShowOpeningHours(true);
        }}
      >
        <ArrowTopRightOnSquareIcon className="me-1" width="20" height="20" />
      </CTableDataCell>

      <CTableDataCell>
        <div className="d-flex gap-2 p-2 align-items-center">
          <Link to={`/places/update/${place.id}`}>
            <EditIcon width="18" height="18" fill={colors.secondary} />
          </Link>{" "}
          {isAdmin && (
            <div onClick={() => setShowModal(true)}>
              <button
                className="outline-none border-0  ps-0 bg-transparent"
                title="supprimer"
              >
                {deletePlace.isLoading ? (
                  <CSpinner size="sm" />
                ) : (
                  <DeleteIcon width="20" height="20" fill={colors.primary} />
                )}
              </button>
            </div>
          )}
          <button
            className="outline-none border-0 bg-transparent"
            onClick={e => {
              setShowDropdown(state => !state);
            }}
          >
            <EllipsisHorizontalIcon className="w-5 h-5" />
          </button>
        </div>
      </CTableDataCell>
      <CModal
        visible={showDropdown}
        onClose={() => setShowDropdown(false)}
        size="lg"
        backdrop="static"
        // className="shadow place-dropdown"
      >
        <CModalHeader>
          <h5 className="fs-5">Contenu complémentaire</h5>
        </CModalHeader>
        <>
          <div className="d-flex flex-column  p-2 ">
            <div className="p-2 ps-0 border-bottom">
              <Link
                className="d-flex align-items-center gap-x-2"
                to={`/places/${place?.id}/invoices`}
              >
                <ArrowTopRightOnSquareIcon
                  className="me-1"
                  width="23"
                  height="23"
                />
                <p className="mb-0">Factures</p>
              </Link>
            </div>
            <div className="p-2 ps-0 border-bottom">
              <Link
                className="d-flex align-items-center gap-x-2"
                to={`${DASHBOARD_ROUTES.payment.list({ place: place?.id })}`}
              >
                <ArrowTopRightOnSquareIcon
                  className="me-1"
                  width="23"
                  height="23"
                />
                <p className="mb-0">Paiement</p>
              </Link>
            </div>
            <div className="p-2 ps-0 border-bottom">
              <Link
                className="d-flex align-items-center gap-x-2"
                to={`/places/${place?.id}/services`}
              >
                <ArrowTopRightOnSquareIcon
                  className="me-1"
                  width="23"
                  height="23"
                />
                <p className="mb-0">Services</p>
              </Link>
            </div>
            {/* <div className="p-2 ps-0 border-bottom">
              <Link
                className="d-flex align-items-center gap-x-2"
                to={`/places/${place?.id}/offers`}
              >
                <ArrowTopRightOnSquareIcon
                  className="me-1"
                  width="23"
                  height="23"
                />
                <p className="mb-0">Offres</p>
              </Link>
            </div> */}
            <div className="p-2 ps-0 border-bottom">
              <Link
                className="d-flex align-items-center gap-x-2"
                to={`/places/${place?.id}/gallery`}
              >
                <ArrowTopRightOnSquareIcon
                  className="me-1"
                  width="23"
                  height="23"
                />
                <p className="mb-0">Galerie</p>
              </Link>
            </div>

            <div className="p-2 ps-0 border-bottom">
              <Link
                className="d-flex align-items-center gap-x-2"
                to={`/places/${place?.id}/ratings`}
              >
                <ArrowTopRightOnSquareIcon
                  className="me-1"
                  width="23"
                  height="23"
                />
                <p className="mb-0">Commentaires</p>
              </Link>
            </div>
            <div className="p-2 ps-0 border-bottom">
              <Link
                className="d-flex align-items-center gap-x-2"
                to={`/places/${place?.id}/inspectors`}
              >
                <ArrowTopRightOnSquareIcon
                  className="me-1"
                  width="23"
                  height="23"
                />
                <p className="mb-0">Inspecteurs</p>
              </Link>
            </div>
            <div
              className="d-flex align-items-center cursor-pointer py-2 border-bottom"
              onClick={() => {
                setShowDropdown(false);
                setShowQrCode(true);
              }}
            >
              <button className="outline-none border-0 ps-0 bg-transparent">
                <QrCodeIcon className="w-5 h-5" />
              </button>
              <p className="mb-0">Qrcode</p>
            </div>
            {place?.supportTableSelection && (
              <Link
                to={`/places/${place.id}/planner`}
                key={place.id}
                className="d-flex align-items-center gap-2 border-bottom py-2"
              >
                <TableCellsIcon width="23" height="23" />
                <p className="mb-0 cursor-pointer">Plans de tables</p>
              </Link>
            )}
            {isAdmin && (
              <>
                <div
                  className="d-flex align-items-center  cursor-pointer py-2"
                  onClick={() => {
                    setShowDropdown(false);
                    setShowPasswordModal(true);
                  }}
                >
                  <button className="outline-none border-0 ps-0 bg-transparent">
                    <LockClosedIcon className="w-5 h-5" />
                  </button>
                  <p className="mb-0">Réinitialiser le mot de passe</p>
                </div>
              </>
            )}
          </div>
        </>
      </CModal>

      <Menu
        visible={MenuModal}
        onClose={() => setMenuModal(false)}
        placeId={place?.id}
      />

      <PromptModal
        open={showModal}
        onAccept={removePlace}
        onClose={() => setShowModal(false)}
      />

      <OpeningHoursModal
        visible={showOpeningHours}
        onClose={() => setShowOpeningHours(false)}
        placeId={showOpeningHours ? place?.id : ""}
        slug={place?.slug}
      />

      <QrCodeModal
        visible={showQrCode}
        onClose={() => setShowQrCode(false)}
        slug={place?.id}
        name={place?.name}
        category={place?.category.slug}
      />
      <ModifyAdminPasswordModal
        visible={showPasswordModal}
        onClose={() => setShowPasswordModal(false)}
        place={place}
      />
    </CTableRow>
  );
}

interface TableProps {
  places: Place[];
  placesToBeDeletedList: React.Dispatch<BulkDeleteActions>;
}
export default function PlacesTable({
  places,
  placesToBeDeletedList
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-sm">
              Nom
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Visible
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Téléphone
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Catégorie
            </CTableHeaderCell>

            {/* <CTableHeaderCell scope="col" className="text-sm">
              Services
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Offres
            </CTableHeaderCell> */}
            <CTableHeaderCell scope="col" className="text-sm">
              Menu
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Heures d'ouverture
            </CTableHeaderCell>
            {/* <CTableHeaderCell scope="col" className="text-sm">
              Galerie
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Commentaires
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Inspecteurs
            </CTableHeaderCell> */}
            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {places?.map((place, index) => (
            <TableBodyRowContent
              place={place}
              key={place?.id}
              deletedList={placesToBeDeletedList}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}
