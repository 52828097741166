import ProtectedRoute from "../auth/ProtectedRoute";

import { useAuth } from "../../hooks/useAuth";
import AdminHome from "./Home";
import PlaceAdminHome from "./PlaceAdminHome";
import Sidebar from "../../components/Sidebar";
import HeaderMenu from "../../components/HeaderMenu";
import { useAuthContext } from "../../context/auth";

export default function Home() {
  const { user } = useAuthContext();
  const { isAdmin, isPlaceAdmin } = useAuth();

  return (
    <ProtectedRoute route="Auth">
      <HeaderMenu />
      <div className="d-flex">
        <Sidebar className="d-lg-block d-none" />
        <div className="w-100 bg-white ">
          <div className=" text-white py-3 px-5 bg-header d-flex align-items-center gap-4 welcome-banner">
            <div className="rounded-circle border border-2 border-danger overflow-hidden bg-white">
              <img
                src={user?.profilePicture as string}
                alt=""
                width={70}
                height={70}
              />
            </div>
            <div>
              {" "}
              <p className="fs-3 fw-bold mb-0 text-capitalize">
                Bonjour {user?.fullName} !
              </p>
              <p>
                Bienvenue à tous ! Gérez vos tâches et votre travail quotidien
                ici.
              </p>
            </div>
          </div>
          <div className="container pt-5 pb-5">
            {isAdmin ? <AdminHome /> : isPlaceAdmin ? <PlaceAdminHome /> : null}
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
}
