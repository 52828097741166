import { CFormInput } from "@coreui/react";
import React from "react";
import { useController } from "react-hook-form";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

interface Props extends InputProps {
  label?: string;
  control: any;
  defaultValue?: string | number;
  name: string;
  type?: string;
  isRequired?: boolean;
  rules?: any;
}
export default function FormInput(props: Props) {
  const {
    name,
    defaultValue,
    control,
    label,
    type = "text",
    placeholder,
    isRequired = false,
    rules
  } = props;
  const { field, fieldState } = useController({
    name,
    defaultValue,
    control,
    rules
  });
  return (
    <div>
      {label && (
        <label className="d-block">
          {label} {isRequired && <span className="text-md text-red">*</span>}
        </label>
      )}
      <CFormInput
        type={type}
        className="custom-input"
        placeholder={placeholder}
        {...field}
      />

      {fieldState.error?.message && (
        <div className="text-red-500 text-opacity-50" style={{ color: "red" }}>
          {fieldState.error.message}
        </div>
      )}
    </div>
  );
}
