import React, { ReactNode } from "react";
import { useQueryParam } from "../../hooks/useQueryParam";
import { useDebounce } from "../../hooks/useDebounce";
import { useSearchParams } from "react-router-dom";
import { CSpinner } from "@coreui/react";
import { TagIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { pluralize } from "../../helpers/general";
import { general } from "../../locales/general";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { colors } from "../../config/theme";

interface Props {
  deleting?: boolean;
  disableDelete: boolean;
  onDelete: () => void;
  onAddItem?: () => void;
  addComponent?: ReactNode;
  totalItems: number;
}

export default function TableBasicHeader(props: Props) {
  const {
    deleting,
    addComponent,
    onDelete,
    onAddItem,
    disableDelete = false,
    totalItems
  } = props;
  const [searchParams] = useSearchParams();

  const query = searchParams?.get("search") ?? "";
  const searchQuery = useDebounce(query, 1000);
  const { add } = useQueryParam();

  return (
    <div>
      <div className="d-flex  justify-content-between align-items-center  ps-2">
        <div className="d-flex align-items-center gap-2">
          {addComponent ? (
            addComponent
          ) : (
            <button
              onClick={onAddItem}
              className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary text-pre"
            >
              + Ajouter un code
            </button>
          )}

          <button
            onClick={onDelete}
            disabled={deleting || disableDelete}
            className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary text-pre"
          >
            {!deleting ? (
              <>
                <DeleteIcon fill={colors.primary} /> Supprimer
              </>
            ) : (
              <>
                <CSpinner size="sm" /> {general.fr.wait}
              </>
            )}
          </button>
        </div>

        <div
          className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3 "
          style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
        >
          <TagIcon width="20" color="white" />
          <p className="fs-5 fw-bold m-0 text-white text-pre">
            {totalItems} {pluralize("Code promo", totalItems, "Codes Promo")}
          </p>
        </div>
      </div>
      {/* <div className="search-form w-85 my-3 mx-auto">
        <div className=" input-field">
          <MagnifyingGlassIcon color="gray" width={20} />
          <input
            type="text"
            className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
            placeholder="Rechercher"
            onChange={e => add("search", e?.target?.value?.toLowerCase())}
            defaultValue={query}
          />
        </div>
      </div> */}
    </div>
  );
}
