import { IconTypes } from "./types";
export default function BagIcon({
  width = "26",
  height = "6",
  ...rest
}: Partial<IconTypes>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 19"
      {...rest}
    >
      <path
        d="M12.7812 7.04252C12.4823 7.04252 12.2344 6.7946 12.2344 6.49564V5.48939C12.2344 4.72377 11.9063 3.98002 11.3375 3.46231C10.7615 2.93731 10.0177 2.69669 9.23021 2.7696C7.91771 2.89356 6.76562 4.23523 6.76562 5.63523V6.34252C6.76562 6.64148 6.51771 6.88939 6.21875 6.88939C5.91979 6.88939 5.67188 6.64148 5.67188 6.34252V5.62794C5.67188 3.66648 7.25417 1.85814 9.12812 1.67585C10.2219 1.57377 11.2719 1.91648 12.074 2.65294C12.8688 3.37481 13.3281 4.41023 13.3281 5.48939V6.49564C13.3281 6.7946 13.0802 7.04252 12.7812 7.04252Z"
        fill="currentColor"
      />
      <path
        d="M11.6876 17.3385H7.31261C3.94386 17.3385 3.31678 15.7708 3.15636 14.2468L2.60949 9.87909C2.52928 9.09159 2.50011 7.96138 3.26574 7.11554C3.92199 6.38638 5.00844 6.03638 6.58344 6.03638H12.4168C13.9991 6.03638 15.0855 6.39367 15.7345 7.11554C16.4928 7.96138 16.4709 9.09159 16.3907 9.8645L15.8438 14.2468C15.6834 15.7708 15.0563 17.3385 11.6876 17.3385ZM6.58344 7.13013C5.35115 7.13013 4.50532 7.37075 4.07511 7.852C3.71782 8.24575 3.60115 8.85096 3.69595 9.75513L4.24282 14.1228C4.36678 15.2895 4.68761 16.252 7.31261 16.252H11.6876C14.3126 16.252 14.6334 15.2968 14.7574 14.1374L15.3043 9.75513C15.3991 8.86554 15.2824 8.26034 14.9251 7.85929C14.4949 7.37075 13.6491 7.13013 12.4168 7.13013H6.58344Z"
        fill="currentColor"
      />
      <path
        d="M11.9943 10.3382C11.5859 10.3382 11.2578 10.0101 11.2578 9.60905C11.2578 9.20801 11.5859 8.87988 11.987 8.87988C12.388 8.87988 12.7161 9.20801 12.7161 9.60905C12.7161 10.0101 12.3953 10.3382 11.9943 10.3382Z"
        fill="currentColor"
      />
      <path
        d="M6.8888 10.3382C6.48047 10.3382 6.15234 10.0101 6.15234 9.60905C6.15234 9.20801 6.48047 8.87988 6.88151 8.87988C7.28255 8.87988 7.61068 9.20801 7.61068 9.60905C7.61068 10.0101 7.28984 10.3382 6.8888 10.3382Z"
        fill="currentColor"
      />
    </svg>
  );
}
