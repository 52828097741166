// @ts-nocheck
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormCheck,
  CFormInput,
  CFormSelect,
  CModal,
  CModalContent,
  CModalHeader,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSpinner,
  CTabContent,
  CTabPane
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import BoxWrapper from "../../../components/BoxWrapper";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import PlusIcon from "../../../components/icons/PlusIcon";
import RichTextEditor from "../../../components/RichTextEditor";
import { useAuth } from "../../../hooks/useAuth";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import apiService from "../../../service/apiService";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ReactSortable } from "react-sortablejs";
import * as yup from "yup";
import FileUploader from "../../../components/FileUploader";
import DotIcon from "../../../components/icons/DotIcon";
import UndoIcon from "../../../components/icons/UndoIcon";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";
import TimeSelectInput from "../../../components/inputs/TimeSelectInput";
import { DAYS_OF_THE_WEEK } from "../../../config/constants";
import { useAuthContext } from "../../../context/auth";
import { FormMode, OFFER_TYPES } from "../../../helpers/enums";
import { toDateInputValue, verifyFileList } from "../../../helpers/general";

const cleanOpeningHoursData = (originalData: any) => {
  const cleanedData = {
    exceptions: originalData?.exceptions ?? [],
    sunday: {},
    saturday: {},
    friday: {},
    thursday: {},
    wednesday: {},
    tuesday: {},
    monday: {}
  };

  // Transform days
  Object.keys(originalData).forEach(day => {
    if (day === "exceptions") return;

    const originalDay = originalData[day];
    const cleanedDay = {};

    if (originalDay.status === "custom") {
      cleanedDay.ranges = originalDay.ranges.filter(
        range => range.to !== "" && range.from !== ""
      );
    } else if (originalDay.status === "openAllDay") {
      cleanedDay.openAllDay = true;
    } else {
      cleanedDay.closed = true;
    }

    cleanedData[day] = cleanedDay;
  });

  // delete cleanedData.exceptions;

  return cleanedData;
};

const getDayHoursType = dayHours => {
  if (dayHours.closed) {
    return "closed";
  } else if (dayHours.openAllDay) {
    return "openAllDay";
  } else {
    return "custom";
  }
};

const transformToFormValue = value => {
  if (value.closed) {
    return { status: "closed" };
  } else if (value.openAllDay) {
    return { status: "openAllDay" };
  } else {
    return {
      status: "custom",
      ranges: value.ranges ?? []
    };
  }
};

const formattedExceptionDates = exceptionDates => {
  return exceptionDates.map(exception => {
    return {
      ...exception,
      date: toDateInputValue(exception.date)
    };
  });
};

const exceptionDates = yup.array().of(
  yup.object({
    name: yup.string(),
    date: yup.date(),
    repeating: yup.string()
  })
);

function DayDisplayValue({ dayHours }) {
  if (dayHours.status === "closed") {
    return <div>Non disponible</div>;
  } else if (dayHours.status === "openAllDay") {
    return <div>Disponible</div>;
  } else if (dayHours.status === "custom") {
    return (
      <div>
        {dayHours.ranges?.map((range, index) => (
          <div key={index}>
            {range.from} - {range.to}
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
}

const dayHours = yup.object({
  status: yup.string().default("closed"),
  ranges: yup.array().when("status", {
    is: "custom",
    then: () =>
      yup
        .array()
        .notRequired()
        .of(
          yup.object().shape({
            from: yup.string(),
            to: yup.string()
          })
        )
  })
});

const openingHoursSchema = yup.object({
  monday: dayHours,
  tuesday: dayHours,
  wednesday: dayHours,
  thursday: dayHours,
  friday: dayHours,
  saturday: dayHours,
  sunday: dayHours,
  exceptions: exceptionDates
});
// !
function DayTimeInput({
  day,
  addOpeningHours,
  openingHours,
  dayEn,
  copyTo,
  formControl,
  formErrors,
  formSetValues,
  formGetValues,
  formWatchValues,
  formRegister
}: DayTimeInputProps) {
  const {
    fields: rangeFields,
    append: appendRange,
    remove: removeRange
  } = useFieldArray({
    control: formControl,
    name: `${dayEn}.ranges`
  });

  const dayHours = formWatchValues(dayEn);
  const isCustomHours = dayHours.status === "custom";

  // console.log("!!! rendered", dayEn, dayHours, isCustomHours, rangeFields);

  return (
    <CAccordionItem itemKey={dayEn}>
      <CAccordionHeader>
        <div className="w-full d-flex justify-content-between pe-2">
          <div>{day}</div>
          <div>
            <DayDisplayValue dayHours={dayHours} />
          </div>
        </div>
      </CAccordionHeader>
      <CAccordionBody>
        <div className="position-relative openingHour-type-wrapper">
          <div className="d-lg-flex align-items-center justify-content-between gap-4">
            <div className="d-lg-flex align-items-center gap-4">
              <CFormCheck
                type="radio"
                name={`${dayEn}-1-openingHr`}
                id={`${dayEn}-1-openingHr`}
                label="Non disponible"
                value="closed"
                {...formRegister(`${dayEn}.status`)}
              />
              <CFormCheck
                type="radio"
                name={`${dayEn}-2-openingHr`}
                id={`${dayEn}-2-openingHr`}
                label="Disponible"
                value="openAllDay"
                {...formRegister(`${dayEn}.status`)}
              />
              <CFormCheck
                type="radio"
                name={`${dayEn}-3-openingHr`}
                id={`${dayEn}-3-openingHr`}
                label="Personnalisé"
                value="custom"
                {...formRegister(`${dayEn}.status`)}
              />
            </div>
            <div>
              <CDropdown direction="center">
                <CDropdownToggle
                  className="outline-none border-0 rounded-sm bg-transparent d-flex align-items-center gap-2"
                  // color="primary"
                >
                  <DotIcon fill="black" width="18" />
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem
                    type="button"
                    onClick={() => {
                      copyTo("workDays", formGetValues(dayEn));
                    }}
                  >
                    Copier vers les jours ouvrés
                  </CDropdownItem>
                  <CDropdownItem
                    type="button"
                    onClick={() => {
                      copyTo("weekends", formGetValues(dayEn));
                    }}
                  >
                    Copier vers les week-ends
                  </CDropdownItem>
                  <CDropdownItem
                    type="button"
                    onClick={() => {
                      copyTo("all", formGetValues(dayEn));
                    }}
                  >
                    Copier vers tous
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </div>
          </div>
          {isCustomHours && (
            <div>
              {dayHours.ranges.map((range, index) => (
                <div
                  key={range.id ?? index}
                  className="d-flex justify-content-between gap-3 mt-2"
                >
                  <div className="w-50">
                    <Controller
                      name={`${dayEn}.ranges.${index}.from`}
                      control={formControl}
                      render={({ field }) => (
                        <TimeSelectInput
                          label="Heure d'ouverture"
                          selectedTime={field.value}
                          onChange={val => field.onChange(val)}
                        />
                      )}
                    />
                  </div>
                  <div className="w-50 d-flex flex-column align-items-end">
                    <Controller
                      name={`${dayEn}.ranges.${index}.to`}
                      control={formControl}
                      render={({ field }) => (
                        <TimeSelectInput
                          label="Heure de clôture"
                          selectedTime={field.value}
                          onChange={val => field.onChange(val)}
                        />
                      )}
                    />
                  </div>
                  <div
                    className="d-flex align-items-end justify-content-end"
                    style={{ width: "45px" }}
                  >
                    {index > 0 && (
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => removeRange(index)}
                        style={{
                          width: "41px",
                          height: "41px"
                        }}
                      >
                        <DeleteIcon width="18" height="18" fill="#ffffff" />
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <div className="mt-2 d-flex align-center justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => appendRange({ from: "", to: "" })}
                >
                  <PlusIcon width="18" height="18" fill="#ffffff" />
                </button>
              </div>
            </div>
          )}
        </div>
      </CAccordionBody>
    </CAccordionItem>
  );
}

interface ModalProps {
  initialValues: any;
  visible: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

const WORK_DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday"];
const WEEKENDS = ["saturday", "sunday"];

export function OpeningHoursModal({
  initialValues,
  visible,
  onClose,
  onSubmit
}: ModalProps) {
  const [activeKey, setActiveKey] = useState(1);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    setValue: setFormValue,
    getValues: getFormValue,
    watch: watchFormValue,
    reset: resetForm
  } = useForm({
    resolver: yupResolver(openingHoursSchema),
    defaultValues: {
      monday: {
        status: "closed",
        ranges: [{ from: "", to: "" }]
      },
      tuesday: {
        status: "closed",
        ranges: [{ from: "", to: "" }]
      },
      wednesday: {
        status: "closed",
        ranges: [{ from: "", to: "" }]
      },
      thursday: {
        status: "closed",
        ranges: [{ from: "", to: "" }]
      },
      friday: {
        status: "closed",
        ranges: [{ from: "", to: "" }]
      },
      saturday: {
        status: "closed",
        ranges: [{ from: "", to: "" }]
      },
      sunday: {
        status: "closed",
        ranges: [{ from: "", to: "" }]
      }
    }
  });

  const {
    fields: exceptionFields,
    append: appendException,
    remove: removeException,
    update: updateException
  } = useFieldArray({
    control: formControl,
    name: "exceptions"
  });

  useEffect(() => {
    if (initialValues) {
      const formFields = Object.keys(openingHoursSchema.fields);
      Object.entries(initialValues).forEach(entry => {
        const [day, value] = entry;
        if (formFields.indexOf(day) !== -1) {
          if (day === "exceptions") {
            setFormValue("exceptions", formattedExceptionDates(value));
          } else {
            const dayHoursType = getDayHoursType(value);
            if (dayHoursType === "custom") {
              setFormValue(day, transformToFormValue(value));
              setFormValue(`${day}.ranges`, value.ranges);
            } else {
              setFormValue(day, transformToFormValue(value));
            }
          }
        }
      });
    }
  }, [resetForm, initialValues, setFormValue]);

  const onSubmitHandler = async (data: any) => {
    const cleanedData = cleanOpeningHoursData(data);
    onSubmit(cleanedData);
  };

  const copyTo = (type: "workDays" | "weekends" | "all", value: any) => {
    console.log(value);
    if (type === "workDays") {
      WORK_DAYS.forEach(day => {
        setFormValue(day, value);
      });
    } else if (type === "weekends") {
      WEEKENDS.forEach(day => {
        setFormValue(day, value);
      });
    } else {
      // all
      Object.keys(openingHoursSchema.fields).forEach(day => {
        setFormValue(day, value);
      });
    }
  };

  return (
    <CModal
      visible={visible ? true : false}
      onClose={() => {
        onClose();
        setActiveKey(1);
      }}
      size="lg"
      backdrop="static"
    >
      <CModalHeader>
        <h5 className="fs-5">Configurer la disponibilité de l'offre</h5>
      </CModalHeader>
      <CModalContent className=" border-0 px-3 py-2">
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <CNav variant="tabs" role="tablist">
            <CNavItem role="presentation">
              <CNavLink
                active={activeKey === 1}
                component="button"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected={activeKey === 1}
                onClick={() => setActiveKey(1)}
              >
              Horaires de disponibilité
              </CNavLink>
            </CNavItem>
            <CNavItem role="presentation">
              <CNavLink
                active={activeKey === 2}
                component="button"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected={activeKey === 2}
                onClick={() => setActiveKey(2)}
              >
                Configurer les jours fériés
              </CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent>
            <CTabPane
              role="tabpanel"
              aria-labelledby="home-tab-pane"
              visible={activeKey === 1}
              className="pt-4"
            >
              <div className="row">
                <CAccordion alwaysOpen>
                  {DAYS_OF_THE_WEEK.map(day => (
                    <div key={day.en} className="col-12">
                      <DayTimeInput
                        day={day.fr}
                        dayEn={day.en}
                        copyTo={copyTo}
                        formControl={formControl}
                        formErrors={errors}
                        formSetValues={setFormValue}
                        formGetValues={getFormValue}
                        formWatchValues={watchFormValue}
                        formRegister={register}
                      />
                    </div>
                  ))}
                </CAccordion>
              </div>
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="profile-tab-pane"
              visible={activeKey === 2}
            >
              {exceptionFields.map((exception, index) => (
                <ExceptionDate
                  key={exception.id}
                  index={index}
                  exception={exception}
                  removeException={() => removeException(index)}
                  updateException={updateException}
                  formControl={formControl}
                  formErrors={errors}
                  formSetValues={setFormValue}
                  formGetValues={getFormValue}
                  formWatchValues={watchFormValue}
                  formRegister={register}
                />
              ))}
              <div className="d-flex justify-content-center mt-4">
                <button
                  type="button"
                  className="btn btn-primary btn-sm text-white"
                  onClick={() =>
                    appendException({ name: "", date: "", repeating: "" })
                  }
                >
                  Ajouter une date
                </button>
              </div>
            </CTabPane>
          </CTabContent>
          <button
            type="submit"
            className="mt-4 btn btn-danger shadow-primary w-100 p-2 d-flex align-items-center justify-content-center text-white"
          >
            Enregistrer
          </button>
        </form>
      </CModalContent>
    </CModal>
  );
}

function ExceptionDate({
  exception,
  index,
  removeException,
  updateException,
  formControl,
  formErrors,
  formSetValues,
  formGetValues,
  formWatchValues,
  formRegister
}) {
  return (
    <div className="d-flex justify-content-between border-bottom py-3">
      <div className="px-2" style={{ flex: 1 }}>
        <label htmlFor="name">Nom</label>
        <CFormInput
          name={`exceptions.${index}.name`}
          id={`exceptions.${index}.name`}
          {...formRegister(`exceptions.${index}.name`)}
        />
      </div>
      <div className="px-2" style={{ flex: 1 }}>
        <label htmlFor="name">Date</label>
        <CFormInput
          type="date"
          name={`exceptions.${index}.date`}
          {...formRegister(`exceptions.${index}.date`)}
        />
      </div>
      <div className="px-2" style={{ flex: 1 }}>
        <label htmlFor="name">Répétition annuelle</label>
        <CFormSelect
          className="mt-0"
          name={`exceptions.${index}.repeating`}
          {...formRegister(`exceptions.${index}.repeating`)}
        >
          <option selected value="">
            Choisissez une option
          </option>
          <option value="true">Oui</option>
          <option value="false">Non</option>
        </CFormSelect>
      </div>
      <div
        className="d-flex align-items-end justify-content-end"
        style={{ width: "45px" }}
      >
        <button
          type="button"
          className="btn btn-danger btn-sm"
          onClick={() => removeException()}
          style={{
            width: "41px",
            height: "41px"
          }}
        >
          <DeleteIcon width="18" height="18" fill="#ffffff" />
        </button>
      </div>
    </div>
  );
}

export default function NewSpecialOffer({ mode = FormMode.CREATE }) {
  const [formLoading, setFormLoading] = useState(false);
  const [showOpeningHours, setShowOpeningHours] = useState(false);
  const [description, setDescription] = useState("");
  const [offerAvailability, setOfferAvailability] = useState(null);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const navigate = useNavigate();
  const params = useParams();
  const { isAdmin } = useAuth();
  const { user } = useAuthContext();
  const [images, setImages] = useState({
    carousel: "",
    main: ""
  });

  const schema = yup.object({
    type: yup.string().nullable(),
    title: yup.string().required("Ce champ est obligatoire"),
    text: yup.string().nullable(),
    reductionPercent: yup
      .number()
      .positive()
      .nullable(true)
      .typeError()
      .transform((_, val) => (val ? Number(val) : null)),
    price: yup
      .number()
      .positive()
      .nullable(true)
      .typeError()
      .transform((_, val) => (val ? Number(val) : null)),
    numberOfPersons: yup
      .number()
      .nullable(true)
      .typeError()
      .transform((_, val) => (val ? Number(val) : null)),
    date: yup
      .date()
      .nullable(true)
      .typeError()
      .transform((_, val) => (val ? new Date(val) : null)),
    duration: yup
      .number()
      .nullable(true)
      .typeError()
      .transform((_, val) => (val ? Number(val) : null)),
    picture: yup.mixed().nullable(),
    from: yup.string().nullable(),
    to: yup.string().nullable(),
    weekDays: yup.array().nullable(),
    carouselImage: yup.mixed().required("Ce champ est obligatoire"),
    // paymentMethod: yup.string().required("Ce champ est obligatoire"),
    paymentMethodsInPlace: yup.boolean().nullable(),
    paymentMethodsCreditCard: yup.boolean().nullable(),
    paymentMethodsWafacash: yup.boolean().nullable(),
    minNumberOfPersons: yup.number().default(1),
    stepOfNumberOfPersons: yup.number().default(1),
    authConfirmBookings: yup.string().required("Ce champ est obligatoire"),
    ...(isAdmin
      ? {
          placeId: yup
            .object()
            .required("Ce champ est obligatoire")
            .typeError("Ce champ est obligatoire")
        }
      : {})
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    setValue: setFormValue,
    watch: watchFormValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      minNumberOfPersons: 1,
      stepOfNumberOfPersons: 1
    }
  });

  const selectedPlace = watchFormValue("placeId");
  const {
    fields: variantItemsFields,
    append: appendVariantItemField,
    remove: removeVariantItemField,
    update: updateVariantItemField,
    move: moveVariantItemField
  } = useFieldArray({
    control: formControl,
    name: "variants"
  });

  useEffect(() => {
    if (mode === FormMode.UPDATE && params?.id) {
      const url =
        isAdmin === true
          ? `specials/all/${params.id}`
          : `specials/ours/${params.id}`;
      const formFields = Object.keys(schema.fields);
      apiService.MakeGetRequest(url, token).then(response => {
        setOfferAvailability(response?.openingHours);        
        setDescription(response?.text);
        setFormValue("placeId", response.place);
        setFormValue("picture", response.image);
        setFormValue("carouselImage", response.carouselPicture);
        setFormValue("weekDays", response.weekDays);
        const paymentOptions = response.paymentOptions ?? [];
        if (paymentOptions?.includes("in_place")) {
          setFormValue("paymentMethodsInPlace", true);
        }
        if (paymentOptions?.includes("credit_card")) {
          setFormValue("paymentMethodsCreditCard", true);
        }
        if (paymentOptions?.includes("wafacash")) {
          setFormValue("paymentMethodsWafacash", true);
        }
        setImages({ carousel: response.carouselPicture, main: response.image });
        if (response?.offerHoursRange) {
          setFormValue("from", response?.offerHoursRange?.from);
          setFormValue("to", response?.offerHoursRange?.to);
        }

        if (response?.variants) {
          setFormValue(
            "variants",
            response?.variants?.map(item => ({
              ...item,
              prix: item?.price,
              maxNumberOfPersons: item?.maxNumberOfPersons ?? "",
              itemId: item.id,
              isSaved: true,
              isDeleted: false
            }))
          );
        }
        Object.entries(response).forEach(entry => {
          if (formFields.indexOf(entry[0]) !== -1) {
            // setFormValue(entry[0], entry[1]);
            if (entry[0] === "date") {
              setFormValue(entry[0], toDateInputValue(entry[1]));
            } else {
              setFormValue(entry[0], entry[1]);
            }
          }
        });
      });
    }
  }, [params?.id]);

  // @desc create new user
  const createNewOfferMutation = useMutation(async (data: SpecialOffers) => {
    return await apiService.MakePostRequest("specials", data, token, true);
  });

  const updateSpecialOfferMutation = useMutation(async data => {
    return await apiService.MakePutRequest(
      `specials/${params.id}`,
      data,
      token,
      true
    );
  });

  const deleteVariantMutation = useMutation(async variantId => {
    return await apiService.MakeDeleteRequest(
      `specials/${params.id}/variant/${variantId}`,
      token
    );
  });

  const createVariantMutation = useMutation(async data => {
    return await apiService.MakePostRequest(
      `specials/${params.id}/variant`,
      data,
      token
    );
  });

  const updateVariantMutation = useMutation(async ({ variantId, data }) => {
    return await apiService.MakePutRequest(
      `specials/${params.id}/variant/${variantId}`,
      data,
      token
    );
  });

  const createSpecialOffer = async data => {
    try {
      setFormLoading(true);
      if (data.weekDays?.length) {
        data.weekDays = JSON.stringify(data.weekDays);
      }
      await createNewOfferMutation.mutateAsync(data);
      toastSuccess(general.fr.message.offerCreated);
      navigate(-1);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const updateSpecialOffer = async data => {
    try {
      setFormLoading(true);
      if (data.weekDays?.length) {
        data.weekDays = JSON.stringify(data.weekDays);
      }
      const variants = data.variants ?? [];
      delete data.variants;
      const updatedSpecial = updateSpecialOfferMutation.mutateAsync(data);
      const variantsToDelete = variants.filter(
        item => item.isDeleted === true && item.isSaved === true
      );
      const variantsToUpdate = variants.filter(
        item => item.isSaved === true && item.isDeleted === false
      );
      const variantsToCreate = variants.filter(item => item.isSaved === false);
      const deleteVariants = variantsToDelete.map(
        async item => await deleteVariantMutation.mutateAsync(item.itemId)
      );
      const updateVariants = variantsToUpdate.map(
        async item =>
          await updateVariantMutation.mutateAsync({
            variantId: item.id,
            data: item
          })
      );
      const createVariants = variantsToCreate.map(
        async item => await createVariantMutation.mutateAsync(item)
      );
      await Promise.all([
        deleteVariants,
        updateVariants,
        createVariants,
        updatedSpecial
      ]);
      // await updateSpecialOfferMutation.mutateAsync(data);
      toastSuccess(general.fr.message.offerUpdated);
      navigate(-1);
    } catch (error) {
      console.log(error);
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const onSubmit = async dataArg => {
    const data = { ...dataArg };
    try {
      if (!offerAvailability) {
        toastError("Veuillez configurer la disponibilité de l'offre");
        return;
      }
      data.type = OFFER_TYPES.SPECIAL;
      const imageFile = verifyFileList(data.picture);
      data.picture = imageFile;
      const carouselImageFile = verifyFileList(data.carouselImage);
      data.carouselImage = carouselImageFile;
      data.placeId = isAdmin ? data.placeId.id : user?.adminForPlace?.id;

      if (data?.from && data?.to) {
        data.offerHoursRange = JSON.stringify({
          from: data.from,
          to: data.to
        });
      }
      if (data?.variants?.length > 0) {
        data.variants = data.variants.map(
          (
            {
              prix,
              description,
              maxNumberOfPersons,
              minNumberOfPersons,
              stepOfNumberOfPersons,
              ...rest
            },
            index
          ) => ({
            ...rest,
            price: +prix,
            description,
            minNumberOfPersons: +minNumberOfPersons,
            stepOfNumberOfPersons: +stepOfNumberOfPersons,
            maxNumberOfPersons:
              maxNumberOfPersons === "" ? "null" : +maxNumberOfPersons,
            order: index
          })
        );
        // delete data?.variants;
      }

      data.openingHours = offerAvailability;

      if (data?.reductionPercent === null) {
        data.reductionPercent = "";
      }
      if (data?.price === null) {
        data.price = "";
      }
      if (!data?.numberOfPersons) {
        data.numberOfPersons = "null";
      }
      if (!data?.date) {
        data.date = "null";
      }
      if (!data?.duration) {
        data.duration = "null";
      }

      const paymentOptions = [];
      if (data.paymentMethodsInPlace) {
        paymentOptions.push("in_place");
      }
      if (data.paymentMethodsCreditCard) {
        paymentOptions.push("credit_card");
      }
      if (data.paymentMethodsWafacash) {
        paymentOptions.push("wafacash");
      }
      data.paymentOptions = JSON.stringify(paymentOptions);

      if (mode === FormMode.UPDATE) {       
        updateSpecialOffer(data);
      } else {
        createSpecialOffer(data);
      }
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const deleteVariantItem = async (item, index) => {
    if (item.isSaved === true) {
      updateVariantItemField(index, { ...item, isDeleted: true });
    } else {
      removeVariantItemField(index);
    }
  };

  return (
    <>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Ajouter une offre spéciale</h4>
            </div>
            <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-6 mt-4">
                <label htmlFor="title" className="d-block">
                  Titre de l&apos;offre{" "}
                  <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="Titre de l'offre"
                  id="title"
                  name="title"
                  {...register("title")}
                />
                {errors.title?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.title.message}
                  </div>
                )}
              </div>
              {isAdmin && (
                <div className="col-md-6 mt-4">
                  <label htmlFor="price" className="d-block mb-2">
                    Établissement <span className="text-md text-red">*</span>
                    {selectedPlace?.isVisible === false ? (
                      <span className="text-attention">
                        {" "}
                        (cet établissement est invisible)
                      </span>
                    ) : null}
                  </label>
                  <Controller
                    name="placeId"
                    control={formControl}
                    render={({ field }) => {
                      return (
                        <InfiniteScrollSelect
                          name="placeId"
                          id="placeId"
                          isClearable={false}
                          error={errors?.placeId?.message}
                          getOptionLabel={option => option?.name}
                          getOptionValue={option => option.id}
                          isOptionSelected={(option, selectedValue) => {
                            const isSelected =
                              option?.id === selectedValue?.[0]?.id;
                            return isSelected;
                          }}
                          url={{
                            path: "places"
                          }}
                          {...field}
                        />
                      );
                    }}
                  />
                  {errors.placeId?.message && (
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{ color: "red" }}
                    >
                      {errors.placeId.message}
                    </div>
                  )}
                </div>
              )}
              <div className="col-md-6 mt-4">
                <label htmlFor="reductionPercent" className="d-block">
                  Remise
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Remise"
                  id="reductionPercent"
                  name="reductionPercent"
                  {...register("reductionPercent")}
                />
                {errors.reductionPercent?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.reductionPercent.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="price" className="d-block">
                  Prix
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Prix"
                  id="price"
                  name="price"
                  {...register("price")}
                />
                {errors.price?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.price.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="numberOfPersons" className="d-block">
                  Nombre de personnes
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder=" Nombre de personnes"
                  id="numberOfPersons"
                  name="numberOfPersons"
                  {...register("numberOfPersons")}
                />
                {errors.numberOfPersons?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.numberOfPersons.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="date" className="d-block">
                  Date
                </label>
                <CFormInput
                  type="date"
                  className="custom-input"
                  placeholder="Date"
                  id="date"
                  name="date"
                  {...register("date")}
                />
                {errors.date?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.date.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="duration" className="d-block">
                  Durée de l&apos;offre (en jours)
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Durée de l'offre"
                  id="duration"
                  name="duration"
                  {...register("duration")}
                />
                {errors.duration?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.duration.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="picture" className="d-block mb-1">
                  Image de l&apos;offre
                </label>
                <FileUploader
                  name="picture"
                  id="picture"
                  accept="image/png,image/jpeg,image/jpg"
                  {...register("picture")}
                  file={images?.main}
                />
                {errors.picture?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.picture.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="carouselImage" className="d-block mb-1">
                  Image du carrousel <span className="text-md text-red">*</span>
                </label>
                <FileUploader
                  name="carouselImage"
                  id="carouselImage"
                  accept="image/png,image/jpeg,image/jpg"
                  {...register("carouselImage")}
                  file={images?.carousel}
                  required={mode === FormMode.UPDATE ? false : true}
                />
                {errors.carouselImage?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.carouselImage.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="picture" className="d-block mb-2">
                  Disponibilité de l&apos;offre
                  <span className="text-sm text-red"> *</span>
                </label>
                <button
                  type="button"
                  style={{
                    width: "100%",
                    color: "white"
                  }}
                  className="btn btn-info"
                  onClick={() => setShowOpeningHours(true)}
                >
                  Configurer la disponibilité de l'offre
                </button>
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="minNumberOfPersons" className="d-block">
                  Nombre minimum de personnes
                  <span className="text-sm text-red"> *</span>
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  id="minNumberOfPersons"
                  name="minNumberOfPersons"
                  {...register("minNumberOfPersons")}
                />
                {errors.minNumberOfPersons?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.minNumberOfPersons.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="stepOfNumberOfPersons" className="d-block">
                  Étape entre le nombre de personnes
                  <span className="text-sm text-red"> *</span>
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  id="stepOfNumberOfPersons"
                  name="stepOfNumberOfPersons"
                  {...register("stepOfNumberOfPersons")}
                />
                {errors.stepOfNumberOfPersons?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.stepOfNumberOfPersons.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="text" className="d-block mb-1">
                  Méthodes de payement
                </label>

                <div>
                  <CFormCheck
                    label="Carte bancaire"
                    id="paymentMethodsCreditCard"
                    {...register("paymentMethodsCreditCard")}
                  />
                  <CFormCheck
                    label="Wafacash"
                    id="paymentMethodsWafacash"
                    {...register("paymentMethodsWafacash")}
                  />
                  <CFormCheck
                    label="Sur place"
                    id="paymentMethodsInPlace"
                    {...register("paymentMethodsInPlace")}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="text" className="d-block mb-1">
                  Description de l&apos;offre
                </label>

                <RichTextEditor
                  defaultValue={description}
                  onChange={data => {
                    setDescription(data);
                    setFormValue("text", data);
                  }}
                />
                {errors.text?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.text.message}
                  </div>
                )}
              </div>
              <div className="col-12">
                <div className="col-sm-6 col-lg-4 my-4 ">
                  <label htmlFor="authConfirmBookings" className="d-block">
                    Confirmer automatiquement les réservations
                    <span className="text-sm text-red"> *</span>
                  </label>
                  <CFormSelect
                    name="authConfirmBookings"
                    id="authConfirmBookings"
                    {...register("authConfirmBookings")}
                  >
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                  </CFormSelect>
                  {errors.authConfirmBookings?.message && (
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{ color: "red" }}
                    >
                      {errors.authConfirmBookings.message}
                    </div>
                  )}
                </div>
              </div>
              <div
                className="col-12 mt-4"
                style={{
                  maxWidth: "fit-content"
                }}
              >
                <h3 className="fs-6 mb-3 border-bottom">Variants</h3>
                <ReactSortable
                  list={[]}
                  setList={() => {}}
                  className="organize__offer_variants_wrapper"
                  onEnd={e => {
                    moveVariantItemField(e.oldIndex, e.newIndex);
                  }}
                >
                  {variantItemsFields.map((variantItem, index) => (
                    <div key={variantItem?.id} className="d-flex">
                      <div
                        className="d-flex p-4 mb-4"
                        style={{
                          border: "1px solid #e5e5e5",
                          borderRadius: "5px"
                        }}
                      >
                        <div
                          className="d-flex"
                          style={{
                            opacity: variantItem?.isDeleted ? 0.4 : 1,
                            pointerEvents: variantItem?.isDeleted
                              ? "none"
                              : "all"
                          }}
                        >
                          <div>
                            <CRow className="mb-3 align-items-center">
                              <CCol className="col-4">
                                <label htmlFor="variant_prix">Prix</label>
                                <CFormInput
                                  type="number"
                                  className="custom-input"
                                  placeholder="Prix"
                                  id="variant_prix"
                                  {...register(`variants.${index}.prix`, {
                                    required: false
                                  })}
                                />
                                {errors?.variants &&
                                  errors?.variants?.[index] &&
                                  !!errors?.variants?.[index]?.prix &&
                                  errors?.variants?.[index]?.prix?.message && (
                                    <div style={{ color: "red" }}>
                                      {errors?.variants?.[index]?.prix?.message}
                                    </div>
                                  )}
                              </CCol>
                              <CCol className="col-8">
                                <label htmlFor="variant_description">
                                  Description
                                </label>
                                <CFormInput
                                  className="custom-input w-100"
                                  placeholder="description"
                                  id="variant_description"
                                  {...register(
                                    `variants.${index}.description`,
                                    {
                                      required: false
                                    }
                                  )}
                                />
                                {errors?.variants &&
                                  errors?.variants?.[index] &&
                                  !!errors?.variants?.[index]?.description &&
                                  errors?.variants?.[index]?.description
                                    ?.message && (
                                    <div style={{ color: "red" }}>
                                      {
                                        errors?.variants?.[index]?.description
                                          ?.message
                                      }
                                    </div>
                                  )}
                              </CCol>
                              <CCol className="col-4">
                                <label htmlFor="variant_minNumberOfPersons">
                                  Nombre minimum de personnes
                                </label>
                                <CFormInput
                                  className="custom-input w-100"
                                  id="variant_minNumberOfPersons"
                                  {...register(
                                    `variants.${index}.minNumberOfPersons`
                                  )}
                                />
                                {errors?.variants &&
                                  errors?.variants?.[index] &&
                                  !!errors?.variants?.[index]
                                    ?.minNumberOfPersons &&
                                  errors?.variants?.[index]?.minNumberOfPersons
                                    ?.message && (
                                    <div style={{ color: "red" }}>
                                      {
                                        errors?.variants?.[index]
                                          ?.minNumberOfPersons?.message
                                      }
                                    </div>
                                  )}
                              </CCol>
                              <CCol className="col-4">
                                <label htmlFor="variant_stepOfNumberOfPersons">
                                  Étape entre le nombre de personnes
                                </label>
                                <CFormInput
                                  className="custom-inpu0t w-100"
                                  id="variant_stepOfNumberOfPersons"
                                  {...register(
                                    `variants.${index}.stepOfNumberOfPersons`
                                  )}
                                />
                                {errors?.variants &&
                                  errors?.variants?.[index] &&
                                  !!errors?.variants?.[index]
                                    ?.stepOfNumberOfPersons &&
                                  errors?.variants?.[index]
                                    ?.stepOfNumberOfPersons?.message && (
                                    <div style={{ color: "red" }}>
                                      {
                                        errors?.variants?.[index]
                                          ?.stepOfNumberOfPersons?.message
                                      }
                                    </div>
                                  )}
                              </CCol>
                              <CCol className="col-4">
                                <label htmlFor="variant_maxNumberOfPersons">
                                  Nombre maximum de personnes
                                </label>
                                <CFormInput
                                  className="custom-input w-100"
                                  id="variant_maxNumberOfPersons"
                                  {...register(
                                    `variants.${index}.maxNumberOfPersons`
                                  )}
                                />
                                {errors?.variants &&
                                  errors?.variants?.[index] &&
                                  !!errors?.variants?.[index]
                                    ?.maxNumberOfPersons &&
                                  errors?.variants?.[index]?.maxNumberOfPersons
                                    ?.message && (
                                    <div style={{ color: "red" }}>
                                      {
                                        errors?.variants?.[index]
                                          ?.maxNumberOfPersons?.message
                                      }
                                    </div>
                                  )}
                              </CCol>
                            </CRow>
                          </div>
                          <div className="ms-3">
                            <CCol className="col-2 mt-4 d-flex">
                              <button
                                type="button"
                                className="btn btn-danger green-shadow w-20 text-white ms-3"
                                onClick={e =>
                                  deleteVariantItem(variantItem, index)
                                }
                              >
                                Supprimer
                              </button>
                            </CCol>
                          </div>
                        </div>
                        <div>
                          <CCol className="col-2 mt-4 d-flex">
                            {variantItem?.isDeleted ? (
                              <button
                                type="button"
                                className="btn btn-danger green-shadow w-20 text-white ms-3"
                                onClick={() =>
                                  updateVariantItemField(index, {
                                    ...variantItem,
                                    isDeleted: false
                                  })
                                }
                              >
                                <UndoIcon />
                              </button>
                            ) : null}
                          </CCol>
                        </div>
                      </div>
                    </div>
                  ))}
                </ReactSortable>

                <button
                  type="button"
                  className="btn btn-info green-shadow w-20 text-white mt-3"
                  onClick={() => {
                    appendVariantItemField({
                      description: "",
                      prix: 0,
                      minNumberOfPersons: 1,
                      stepOfNumberOfPersons: 1,
                      isSaved: false,
                      isDeleted: false
                    });
                  }}
                >
                  Ajouter un élément de variant
                </button>
              </div>
              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </button>
                <button
                  className="btn btn-success green-shadow w-20 text-white"
                  type="submit"
                  disabled={formLoading}
                >
                  {formLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
          <OpeningHoursModal
            initialValues={offerAvailability}
            visible={showOpeningHours}
            onClose={() => setShowOpeningHours(false)}
            onSubmit={data => {              
              setOfferAvailability(data);
              setShowOpeningHours(false);
            }}
          />
        </BoxWrapper>
      </div>
    </>
  );
}
