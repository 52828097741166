import React, { useEffect, useMemo, useReducer, useState } from "react";

import { useParams } from "react-router-dom";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CSpinner } from "@coreui/react";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { useToast } from "../../hooks/useToast";
import { useCookie } from "../../hooks/useCookie";
import apiService from "../../service/apiService";
import { usePaginatedContent } from "../../hooks/usePaginatedContent";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import { general } from "../../locales/general";
import BoxWrapper from "../../components/BoxWrapper";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { colors } from "../../config/theme";
import Pagination from "../../components/Pagination";
import RenderTable from "../../components/RenderTable";
import InspectorsTable from "./InspectorsTable";
import RestrictedRoute from "../auth/RestrictedRoute";
import { useDebounce } from "../../hooks/useDebounce";
import { toQuery } from "../../helpers/general";

const PAGE_SIZE = 10;

export default function Inspectors() {
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const queryClient = useQueryClient();
  const params = useParams();

  const query = useDebounce(searchQuery, 300);

  const { data, isLoading } = useQuery(
    ["getInspectors", currentPage, query],
    () => {
      return apiService.MakeGetRequest(
        `inspectors${toQuery({ page: currentPage, limit: PAGE_SIZE, query })}`,
        token
      );
    }
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [query]);

  const deleteInspector = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`inspectors/${params.id}`, token);
  });

  const paginatedContent = usePaginatedContent(
    currentPage,
    PAGE_SIZE,
    data?.items
  );

  const filteredInspector = data?.items;

  const [InspectorToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    InspectorToBeDeleted.ids.forEach((id: string) => {
      if (!id) return;
      deleteInspector.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.inspectorCreated);
          queryClient.invalidateQueries("getInspectors");
        }
      });
    });
  };
  const InspectorsSvg = () => {
    return (
      <svg
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
        />{" "}
      </svg>
    );
  };
  return (
    <RestrictedRoute>
      <h5 className="fs-4 fw-bold mb-2">Inspecteurs</h5>
      <BoxWrapper>
        <div className="d-flex  justify-content-between align-items-baseline before-table">
          <div className="d-flex align-items-center gap-2 p-3 delete-add">
            <button
              disabled={
                deleteInspector.isLoading ||
                InspectorToBeDeleted?.ids.length === 0
              }
              onClick={handleBulkDelete}
              className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
            >
              {!deleteInspector.isLoading ? (
                <>
                  <DeleteIcon fill={colors.primary} /> Supprimer
                  l&apos;inspecteur
                </>
              ) : (
                <>
                  <CSpinner size="sm" /> {general.fr.wait}
                </>
              )}
            </button>
          </div>
          <div className="search-form">
            <div className=" input-field">
              <MagnifyingGlassIcon color="gray" width={20} />
              <input
                type="text"
                className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                placeholder="Rechercher"
                onChange={e => setSearchQuery(e?.target?.value?.toLowerCase())}
              />
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
            style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
          >
            <InspectorsSvg />
            <p className="fs-5 fw-bold m-0  text-white">
              {" "}
              {data?.meta?.itemCount}{" "}
              {data?.meta?.itemCount > 1 ? "Inspecteurs" : "Inspecteur"}
            </p>
          </div>
        </div>

        <RenderTable
          loading={isLoading}
          render={() =>
            filteredInspector.length > 0 ? (
              <InspectorsTable
                Inspectors={filteredInspector as Inspector[]}
                InspectorsTableToBeDeleted={dispatch}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={0}
        totalCount={data?.meta?.totalPages}
        pageSize={PAGE_SIZE}
      />
    </RestrictedRoute>
  );
}
