import { TrashIcon } from "@heroicons/react/24/solid";
import { Controller, useFieldArray } from "react-hook-form";
import { useQuery } from "react-query";
import FormInput from "../../components/FormInput";
import { colors } from "../../config/theme";
import { useCookie } from "../../hooks/useCookie";
import apiService from "../../service/apiService";
import { ErrorMessage } from "./CreatePromoCode";

interface EventServiceProps {
  control: any;
  event: Events;
  maxUsage: number;
}

export default function EventServicesSection(props: EventServiceProps) {
  const { control, event, maxUsage } = props;

  const { fields, append, remove } = useFieldArray({
    name: "items",
    control
  });

  return (
    <div className="mt-4 ">
      <p className="mb-1">Services de l'événement</p>
      <div className="border rounded-sm p-2">
        {fields.map((field, index) => (
          <EventService
            index={index}
            key={index}
            control={control}
            event={event}
            onRemove={() => remove(index)}
          />
        ))}

        <div className="d-flex justify-content-center mt-2">
          <button
            disabled={event === undefined || maxUsage === 0}
            type="button"
            className="btn"
            onClick={append}
          >
            + Add service
          </button>
        </div>
      </div>
    </div>
  );
}

interface Props {
  index: number;
  control: any;
  event: Events;
  onRemove: () => void;
}
function EventService({ control, index, event, onRemove }: Props) {
  const { token } = useCookie("vToken");

  const { data, isLoading } = useQuery<Events>({
    queryKey: ["getEvent", event, token],
    queryFn: () => apiService.MakeGetRequest(`events/${event?.id}`, token),
    enabled: event?.id?.length > 0
  });

  if (isLoading || !data || !data.services) {
    return;
  }

  return (
    <div
      key={index}
      className="d-flex justify-content-between gap-2 align-items-center mb-2"
    >
      <Controller
        name={`items.${index}.serviceId`}
        control={control}
        defaultValue={data?.services[0].id}
        render={({ field, fieldState }) => (
          <div className="w-100">
            <select {...field} onChange={e => field.onChange(e.target.value)}>
              {data?.services?.map(services => (
                <option key={services.id} value={services.id}>
                  {services.label}
                </option>
              ))}
            </select>
            <ErrorMessage error={fieldState?.error?.message} />
          </div>
        )}
      />
      <div className="w-100">
        <FormInput
          type="number"
          name={`items.${index}.maxUses`}
          control={control}
          placeholder="max usages"
        />
      </div>
      <button type="button" className="btn" onClick={onRemove}>
        <TrashIcon width="15" color={colors.primary} />
      </button>
    </div>
  );
}
