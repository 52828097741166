import { CSpinner } from "@coreui/react";
import { FormEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useCookie } from "../../../hooks/useCookie";
import apiService from "../../../service/apiService";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import BoxWrapper from "../../../components/BoxWrapper";
import PasswordInput from "../../../components/inputs/PasswordInput";

export default function PlaceUpdateInspector() {
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const { placeId, inspectorId } = useParams();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [inspector, setInspector] = useState<Inspector | null>(null);

  const updateInspectorMutation = useMutation(
    async (placeInspector: typeof userInfo) => {
      return await apiService.MakePutRequest(
        `places/inspectors/${inspectorId}`,
        placeInspector,
        token
      );
    }
  );

  const handleInspectorInfo = (key: string, value: string) => {
    setUserInfo(info => ({ ...info, [key]: value }));
  };

  const { data: Inspectors } = useQuery(["getSingleInspector"], () => {
    return apiService.MakeGetRequest(`places/${placeId}/inspectors`, token);
  });

  useEffect(() => {
    if (Inspectors) {
      setInspector(
        Inspectors?.items?.find(
          (inspector: Inspector) => inspector?.id === inspectorId
        )
      );
    }
  }, [Inspectors]);

  const updateInspectorHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      placeInspector: JSON.stringify(userInfo)
    };

    updateInspectorMutation.mutate(userInfo, {
      onSuccess(data: any) {
        toastSuccess(general.fr.message.inspectorCreated);
        navigate(`/places/${placeId}/inspectors`);
      },
      onError(error: any) {
        toastError(general.fr.message.operationFailed);
      }
    });
  };
  return (
    <>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Inspecteurs d'établissement</h4>
            </div>
            <form className="row form " onSubmit={updateInspectorHandler}>
              <section className="mt-4">
                <div className="row form ">
                  <div className="col-md-6 mt-4">
                    <label htmlFor="fullName" className="d-block">
                      Nom complet
                    </label>
                    <input
                      defaultValue={inspector?.fullName}
                      type="text"
                      className="custom-input"
                      placeholder=" Nom complet"
                      id="fullName"
                      name="fullName"
                      onChange={e =>
                        handleInspectorInfo("fullName", e.target.value)
                      }
                      required
                    />
                  </div>

                  <div className="col-md-6 mt-4">
                    <label htmlFor="email" className="d-block">
                      Email
                    </label>
                    <input
                      defaultValue={inspector?.email}
                      autoComplete="new-email"
                      type="email"
                      className="custom-input"
                      placeholder="Email"
                      id="email"
                      name="email"
                      onChange={e =>
                        handleInspectorInfo("email", e.target.value)
                      }
                      required
                    />
                  </div>

                  <div className="col-md-6 mt-4">
                    <label htmlFor="phone" className="d-block">
                      Téléphone
                    </label>
                    <input
                      defaultValue={inspector?.phone}
                      type="text"
                      className="custom-input"
                      placeholder="Téléphone"
                      id="phone"
                      name="phone"
                      onChange={e =>
                        handleInspectorInfo("phone", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <PasswordInput
                      autoComplete="new-password"
                      // isRequired
                      className="custom-input"
                      placeholder="Mot de passe"
                      id="password"
                      name="password"
                      showAsteriks={false}
                      onChange={e =>
                        handleInspectorInfo("password", e.target.value)
                      }
                      labelText=" Mot de passe"
                      minLength={8}
                    />
                  </div>
                </div>
              </section>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <Link
                  to={`/places/${placeId!}/inspectors`}
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </Link>
                <button
                  className="btn btn-success green-shadow w-20 text-white"
                  type="submit"
                  disabled={updateInspectorMutation.isLoading}
                >
                  {updateInspectorMutation.isLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </>
  );
}
