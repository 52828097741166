// @ts-nocheck
import { CSpinner } from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import BoxWrapper from "../../../components/BoxWrapper";
import FileUploader from "../../../components/FileUploader";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";
import { useAuthContext } from "../../../context/auth";
import { FormMode, OFFER_TYPES } from "../../../helpers/enums";
import { verifyFileList } from "../../../helpers/general";
import { useAuth } from "../../../hooks/useAuth";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import apiService from "../../../service/apiService";

const schema = yup.object({
  eventId: yup
    .object()
    .required("Ce champ est obligatoire")
    .typeError("Ce champ est obligatoire"),
  carouselImage: yup.mixed().required("Ce champ est obligatoire")
});

const getSelectInputUrl = (isAdmin: boolean) => {
  if (isAdmin) {
    return "events";
  }
  return "events/ours";
};

export default function NewEventOffer({ mode = FormMode.CREATE }) {
  const [description, setDescription] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [image, setImage] = useState("");
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const navigate = useNavigate();
  const params = useParams();
  const { isAdmin } = useAuth();
  const { user } = useAuthContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    setValue: setFormValue,
    getValues: getFormValue
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (mode === FormMode.UPDATE && params?.id) {
      const formFields = Object.keys(schema.fields);
      apiService.MakeGetRequest(`specials/${params.id}`).then(response => {
        setDescription(response?.text);
        setFormValue("eventId", response.event);
        setFormValue("carouselImage", response.carouselPicture);
        setImage(response.carouselPicture);
        Object.entries(response).forEach(entry => {
          if (formFields.indexOf(entry[0]) !== -1) {
            setFormValue(entry[0], entry[1]);
          }
        });
      });
    }
  }, []);

  // @desc create new user
  const createNewOfferMutation = useMutation(async (data: SpecialOffers) => {
    return await apiService.MakePostRequest("specials", data, token, true);
  });

  const updateNewOfferMutation = useMutation(async (data: SpecialOffers) => {
    return await apiService.MakePutRequest(
      `specials/${params.id}`,
      data,
      token,
      true
    );
  });

  const createSpecialEvent = async data => {
    try {
      setFormLoading(true);
      await createNewOfferMutation.mutateAsync(data);
      toastSuccess(general.fr.message.specialEventCreated);
      navigate(-1);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const updateSpecialEvent = async data => {
    try {
      setFormLoading(true);
      await updateNewOfferMutation.mutateAsync(data);
      toastSuccess(general.fr.message.offerUpdated);
      navigate(-1);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const onSubmit = async dataArg => {
    const data = { ...dataArg };
    try {
      data.type = OFFER_TYPES.EVENT;
      const carouselImageFile = verifyFileList(data.carouselImage);
      data.carouselImage = carouselImageFile;
      data.eventId = data.eventId.id;
      if (mode === FormMode.UPDATE) {
        updateSpecialEvent(data);
      } else {
        createSpecialEvent(data);
      }
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Ajouter une offre d&apos;événement</h4>
            </div>
            <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-6 mt-4">
                <label htmlFor="eventId" className="d-block mb-2">
                  Événement <span className="text-md text-red">*</span>
                </label>
                <Controller
                  name="eventId"
                  control={formControl}
                  render={({ field }) => {
                    return (
                      <InfiniteScrollSelect
                        name="eventId"
                        id="eventId"
                        isClearable={false}
                        error={errors?.eventId?.message}
                        getOptionLabel={option => option?.name}
                        getOptionValue={option => option.id}
                        isOptionSelected={(option, selectedValue) => {
                          const isSelected =
                            option?.id === selectedValue?.[0]?.id;
                          return isSelected;
                        }}
                        url={{
                          path: getSelectInputUrl(isAdmin)
                        }}
                        {...field}
                      />
                    );
                  }}
                />
                {errors.placeId?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.placeId.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="carouselImage" className="d-block mb-1">
                  Image du carrousel <span className="text-md text-red">*</span>
                </label>
                {/* {(mode === FormMode.UPDATE && getFormValue('carouselImage')) ? <ImagePreview url={getFormValue('carouselImage')} /> : null} */}
                <FileUploader
                  type="file"
                  id="carouselImage"
                  name="carouselImage"
                  file={image}
                  accept="image/png,image/jpeg,image/jpg"
                  required={mode === FormMode.UPDATE ? false : true}
                  {...register("carouselImage")}
                />
                {errors.carouselImage?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.carouselImage.message}
                  </div>
                )}
              </div>
              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </button>
                <button
                  className="btn btn-success green-shadow w-20 text-white"
                  type="submit"
                  disabled={formLoading}
                >
                  {formLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </>
  );
}
