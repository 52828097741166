import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import BoxWrapper from "../../components/BoxWrapper";
import { SidebarIcon } from "../../components/icons/SidebarIcons";
import Pagination from "../../components/Pagination";
import RenderTable from "../../components/RenderTable";
import { pluralize, toQuery } from "../../helpers/general";
import { useCookie } from "../../hooks/useCookie";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";
import AdminRoute from "../auth/RestrictedRoute";
import AdvertisementTable from "./AdvertisementTable";

const PAGE_SIZE = 10;

// interface Publication {
//   id: string;
//   relatedType: 'event' | 'place';
//   image: string | null;
//   isActive: boolean;
//   place: Place | null;  // Change to reference Place object
//   event: Event | null;  // Change to reference Event object
// }


export default function Notifications() {
  const [currentPage, setCurrentPage] = useState(1);
  const { token } = useCookie("vToken");

  // Updated endpoint to fetch publications
  const endpoint = `publications/all${toQuery({
    page: currentPage,
    limit: PAGE_SIZE,
  })}`;

  
  const { data, isLoading, isPreviousData , refetch } = useQuery(
    ["getAllPublications", token, currentPage],
    () => apiService.MakeGetRequest(endpoint, token),
    {
      keepPreviousData: true, 
    }
  );

  return (
    <AdminRoute>
      <section>
        <h5 className="fs-4 fw-bold mb-2">Publications</h5>
        <BoxWrapper>
          <div className="d-flex flex-column-reverse flex-md-row justify-content-between align-items-start align-items-md-baseline">
            <div className="d-flex col-12 col-md-6 align-items-center gap-2 p-3 delete-add">
              <Link
                to="/advertisement/new"
                className="btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
              >
                + Ajouter une publication
              </Link>
            </div>
            <div
              className="d-flex col-12 col-md-5 col-lg-4 col-xl-3 align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
              style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
            >
              <SidebarIcon.AdvertisementSVG className="fill-white" />
              <p className="fs-5 fw-bold m-0 text-white">
                {data?.meta?.totalItems}{" "}
                {pluralize("Publication", data?.meta?.totalItems)}
              </p>
            </div>
          </div>

          <RenderTable
            loading={isLoading || isPreviousData}
            render={() =>
              data?.items?.length > 0 ? (
                <AdvertisementTable
                  publications={data?.items as Publication[]}
                  refetch={refetch}
                />
              ) : (
                <p className="text-center fs-5 my-2">{general.fr.noData}</p>
              )
            }
          />
        </BoxWrapper>
        <Pagination
          currentPage={currentPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          siblingCount={0}
          totalCount={data?.meta?.totalPages}
          pageSize={PAGE_SIZE}
        />
      </section>
    </AdminRoute>
  );
}
