import { CSpinner } from "@coreui/react";
import { FormEvent, useState } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../../hooks/useToast";
import { useCookie } from "../../../../hooks/useCookie";
import apiService from "../../../../service/apiService";
import { general } from "../../../../locales/general";
import BoxWrapper from "../../../../components/BoxWrapper";
import PasswordInput from "../../../../components/inputs/PasswordInput";

const DEFAULT_USER_INFO = {
  fullName: "",
  email: "",
  phone: "",
  password: ""
};

export default function PlaceNewInspector() {
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const params = useParams();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(DEFAULT_USER_INFO);

  const createNewInspectorMutation = useMutation(
    async (placeInspector: typeof userInfo) => {
      return await apiService.MakePostRequest(
        `places/${params?.placeId}/inspectors`,
        placeInspector,
        token
      );
    }
  );

  const handleInspectorInfo = (key: string, value: string) => {
    setUserInfo(info => ({ ...info, [key]: value }));
  };

  // @desc form submit handler
  const createInspectorHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      placeInspector: JSON.stringify(userInfo)
    };

    createNewInspectorMutation.mutate(userInfo, {
      onSuccess(data: any) {
        toastSuccess(general.fr.message.inspectorCreated);
        navigate(`/places/${params?.placeId}/inspectors`);
      },
      onError(error: any) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      }
    });
  };
  return (
    <>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Inspecteurs d'établissement</h4>
            </div>
            <form className="row form " onSubmit={createInspectorHandler}>
              <section className="mt-4">
                <div className="row form ">
                  <div className="col-md-6 mt-4">
                    <label htmlFor="fullName" className="d-block">
                      Nom complet
                    </label>
                    <input
                      type="text"
                      className="custom-input"
                      placeholder=" Nom complet"
                      id="fullName"
                      name="fullName"
                      onChange={e =>
                        handleInspectorInfo("fullName", e.target.value)
                      }
                      required
                    />
                  </div>

                  <div className="col-md-6 mt-4">
                    <label htmlFor="email" className="d-block">
                      Email
                    </label>
                    <input
                      autoComplete="new-email"
                      type="email"
                      className="custom-input"
                      placeholder="Email"
                      id="email"
                      name="email"
                      onChange={e =>
                        handleInspectorInfo("email", e.target.value)
                      }
                      required
                    />
                  </div>

                  <div className="col-md-6 mt-4">
                    <label htmlFor="phone" className="d-block">
                      Téléphone
                    </label>
                    <input
                      type="text"
                      className="custom-input"
                      placeholder="Téléphone"
                      id="phone"
                      name="phone"
                      onChange={e =>
                        handleInspectorInfo("phone", e.target.value)
                      }
                    />
                  </div>

                  <div className="col-md-6 mt-4">
                    <PasswordInput
                      autoComplete="new-password"
                      isRequired
                      className="custom-input"
                      placeholder="Mot de passe"
                      id="password"
                      name="password"
                      showAsteriks={false}
                      onChange={e =>
                        handleInspectorInfo("password", e.target.value)
                      }
                      labelText=" Mot de passe"
                      minLength={8}
                    />
                  </div>
                </div>
              </section>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <Link
                  to={`/places/${params?.placeId!}/inspectors`}
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </Link>
                <button
                  className="btn btn-success green-shadow w-20 text-white"
                  type="submit"
                  disabled={createNewInspectorMutation.isLoading}
                >
                  {createNewInspectorMutation.isLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </>
  );
}
