import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { general } from "../locales/general";
import apiService from "../service/apiService";
import { useCookie } from "./useCookie";
import { useToast } from "./useToast";

interface CreateZone {
  name: string;
  placeObjects: [];
}
interface AddObjectPhoto {
  objectId: string;
  data: { picture: File };
}

type UpdateZone = Partial<CreateZone> & { zoneId: string };

export function usePlaceZone() {
  const { id: placeId } = useParams();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const { toastError, toastSuccess } = useToast();

  const { data } = useQuery<Array<Zone>>(
    ["getPlaceZone"],
    () => {
      return apiService.MakeGetRequest(`places/${placeId!}/zones`, token);
    },
    { enabled: placeId!.length > 0 }
  );

  const createZoneMutation = useMutation((data: CreateZone) => {
    return apiService.MakePostRequest(`places/${placeId!}/zones`, data, token);
  });
  const addObjectPhotosMutation = useMutation((details: AddObjectPhoto) => {
    return apiService.MakePutRequest(
      `places/objects/${details.objectId!}/pictures/add`,
      details.data,
      token,
      true
    );
  });
  const updateZoneMutation = useMutation((data: UpdateZone) => {
    return apiService.MakePutRequest(
      `places/zones/${data.zoneId}`,
      data,
      token
    );
  });
  const deleteZoneMutation = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`places/zones/${id}`, token);
  });

  return {
    getZones: () => data,
    createZone: (data: CreateZone, success: () => void) => {
      createZoneMutation.mutate(data, {
        onSuccess: () => {
          success();
          queryClient.invalidateQueries("getPlaceZone");
        },
        onError(error) {
          toastError(general.fr.message.failedToCreateZone);
        }
      });
    },
    updateZone: async (data: UpdateZone, success?: () => void) => {
      await updateZoneMutation.mutate(data, {
        onSuccess: () => {
          // toastSuccess(general.fr.message.zoneUpdate);
          success?.();
          queryClient.invalidateQueries("getPlaceZone");
        },
        onError(error) {
          toastError(general.fr.message.failedToUpdateZone);
        }
      });
    },
    addPhoto: (details: AddObjectPhoto) => {
      addObjectPhotosMutation.mutate(details, {
        onSuccess: () => {
          toastSuccess(general.fr.message.zoneUpdate);
          queryClient.invalidateQueries("getPlaceZone");
        },
        onError(error) {
          toastError(general.fr.message.failedToUpdateZone);
          console.log(error);
        }
      });
    },
    deleteZone: (id: string, success?: () => void) => {
      deleteZoneMutation.mutate(id, {
        onSuccess: () => {
          success && success();
          queryClient.invalidateQueries("getPlaceZone");
        },
        onError(error) {
          toastError(general.fr.message.zoneDeleted);
        }
      });
    },
    creatingZone: createZoneMutation.isLoading,
    updatingZone: updateZoneMutation.isLoading,
    deletingZone: deleteZoneMutation.isLoading,
    addingPhoto: addObjectPhotosMutation.isLoading
  };
}
