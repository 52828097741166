import {
  CFormCheck,
  CFormInput,
  CModal,
  CModalBody,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useToast } from "../../../hooks/useToast";
import { useMutation, useQueryClient } from "react-query";
import { useCookie } from "../../../hooks/useCookie";
import apiService from "../../../service/apiService";
import { DELETE_ACTIONS } from "../../../config/constants";
import { general } from "../../../locales/general";
import { colors } from "../../../config/theme";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import EditIcon from "../../../components/icons/EditIcon";
import PromptModal from "../../../components/modals/PromptModal";

function TableBodyRowContent({
  placeId,
  inspector,
  deletedList
}: {
  placeId: string;
  inspector: Inspector;
  deletedList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);

  const deleteInspector = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`inspectors/${id}`, token);
  });

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: inspector.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: inspector.id });
    }
  }, [deletedList, isChecked, inspector.id]);

  const removeInspector = () => {
    deleteInspector.mutate(inspector.id, {
      onError(error) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.inspectorDeleted);
        queryClient.invalidateQueries("getPlaceInspectors");
      }
    });
  };

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 ">
        <CFormCheck
          id="flexCheckDefault"
          label=""
          defaultChecked={isChecked}
          onChange={e => setIsChecked(e.target.checked)}
          color={colors.yellow}
        />
        <span className="ms-2 text-sm">
          {new Date(inspector.createdAt).toLocaleDateString("fr")}
        </span>{" "}
      </CTableDataCell>

      <CTableDataCell className="p-3 text-sm">
        {inspector?.fullName}
      </CTableDataCell>

      <CTableDataCell className="p-3 text-sm">
        {inspector?.email}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {inspector?.phone}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {inspector?.inspectorForPlace?.name}
      </CTableDataCell>

      <CTableDataCell className="p-3">
        <Link
          to={`/places/${placeId}/inspectors/${inspector?.id}/update`}
          key={inspector.id}
        >
          <EditIcon width="18" height="18" fill={colors.secondary} />
        </Link>
        <button
          className="outline-none border-0 bg-transparent"
          onClick={() => setShowModal(true)}
        >
          {deleteInspector.isLoading ? (
            <CSpinner size="sm" />
          ) : (
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          )}
        </button>
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={() => removeInspector()}
        onClose={() => setShowModal(false)}
      />
    </CTableRow>
  );
}

interface TableProps {
  placeId: string;
  inspectors: Inspector[];
  inspectorsToBeDeleted: React.Dispatch<BulkDeleteActions>;
}

export default function PlaceInspectorsTable({
  placeId,
  inspectors,
  inspectorsToBeDeleted
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Date</CTableHeaderCell>
            <CTableHeaderCell scope="col">Nom</CTableHeaderCell>
            <CTableHeaderCell scope="col">Email</CTableHeaderCell>
            <CTableHeaderCell scope="col">Téléphone</CTableHeaderCell>
            <CTableHeaderCell scope="col">Place</CTableHeaderCell>
            <CTableHeaderCell scope="col"></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {inspectors?.map(inspector => (
            <TableBodyRowContent
              placeId={placeId}
              inspector={inspector}
              key={inspector?.id}
              deletedList={inspectorsToBeDeleted}
            />
          ))}{" "}
        </CTableBody>
      </CTable>
    </div>
  );
}
