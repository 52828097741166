import { IconTypes } from "./types";
export default function PercentageIcon({
  width = "26",
  height = "6",
  ...rest
}: Partial<IconTypes>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M9 14a5 5 0 1 1 5-5a5.006 5.006 0 0 1-5 5m0-8a3 3 0 1 0 3 3a3.003 3.003 0 0 0-3-3M4 26.586L26.585 4L28 5.415L5.414 28zM23 28a5 5 0 1 1 5-5a5.006 5.006 0 0 1-5 5m0-8a3 3 0 1 0 3 3a3.003 3.003 0 0 0-3-3"
      ></path>
    </svg>
  );
}
