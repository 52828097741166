import { CForm, CFormInput, CFormSelect, CSpinner } from "@coreui/react";
import React, { FormEvent, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import BoxWrapper from "../../../components/BoxWrapper";
import RichTextEditor from "../../../components/RichTextEditor";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import apiService from "../../../service/apiService";
import { useEventQuery } from "../../../hooks/events/useEventQuery";
import { useAuth } from "../../../hooks/useAuth";
import { usePlacesQuery } from "../../../hooks/place/usePlacesQuery";
import { FormSelectAPI } from "../../../components/FormReactSelect";
import { createZoneAwareDate } from "../../../helpers/general";

interface SpecialOffers {
  title: string;
  text: string;
  reductionPercent: number;
  picture?: File | string;
  carouselImage?: File | string;
}

export enum OFFER_TYPES {
  SPECIAL = "SPECIAL_OFFER",
  TOUR = "TOUR_OFFER",
  EVENT = "EVENT_OFFER"
}

export default function NewOffer() {
  const [description, setDescription] = useState("");
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [offerType, setOfferType] = useState<string>(OFFER_TYPES.SPECIAL);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { events } = useEventQuery();
  const { Places } = usePlacesQuery();
  const { isAdmin } = useAuth();

  // @desc create new user
  const createNewOfferMutation = useMutation(async (data: SpecialOffers) => {
    return await apiService.MakePostRequest("specials", data, token, true);
  });

  // @desc form submit handler
  const createOfferHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const placeId = formData.get("placeId") as string;
    const title = formData.get("title") as string;
    const offerType = formData.get("offerType") as string;
    const eventId = formData.get("eventId") as string;
    const numberOfPersons = formData.get("numberOfPerson") as string;
    const date = formData.get("date") as string;
    const duration = formData.get("duration") as string;
    const hour = formData.get("hour") as string;
    const price = formData.get("price") as string;
    // const text = formData.get("text") as string;
    const reductionPercent = formData.get("reductionPercent") as string;
    const picture = formData.get("picture") as File;
    const carouselImage = formData.get("carouselImage") as File;

    const data = {
      title,
      text: description,
      reductionPercent: Number(reductionPercent),
      carouselImage,
      picture,
      type: offerType,
      eventId,
      numberOfPersons,
      date: date ? createZoneAwareDate(new Date(date)) : "",
      duration,
      hour,
      price,
      placeId
    };
    createNewOfferMutation.mutate(data, {
      onSuccess(data: any) {
        toastSuccess(general.fr.message.offerCreated);
        queryClient.invalidateQueries("getAllOffers");
        navigate(`/offers`);
      },
      onError(error: any) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      }
    });
  };
  return (
    <>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Ajouter une offre</h4>
            </div>
            <CForm
              validated={true}
              className="row form mt-4"
              onSubmit={createOfferHandler}
            >
              <div className="col-md-6 mt-4">
                <label htmlFor="title" className="d-block">
                  Titre de l&apos;offre{" "}
                  <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="Titre de l'offre"
                  id="title"
                  name="title"
                  required
                  disabled={offerType === OFFER_TYPES.EVENT}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="title" className="d-block">
                  Sélectionnez le type d&apos;offre{" "}
                  <span className="text-md text-red">*</span>
                </label>
                <CFormSelect
                  name="offerType"
                  id="offerType"
                  className="custom-input"
                  required
                  onChange={e => setOfferType(e.target.value)}
                >
                  <option value="SPECIAL_OFFER">OFFRE SPÉCIALE</option>
                  <option value="EVENT_OFFER">OFFRE D&apos;ÉVÉNEMENT</option>
                  <option value="TOUR_OFFER">OFFRE DE TOURISME</option>
                </CFormSelect>
              </div>

              {offerType === OFFER_TYPES.EVENT && (
                <div className="col-md-6 mt-4">
                  <label htmlFor="eventId" className="d-block mb-2">
                    Événement <span className="text-md text-red">*</span>
                  </label>
                  <FormSelectAPI
                    // @ts-ignore
                    name="eventId"
                    id="eventId"
                    isClearable={false}
                    getOptionLabel={(option: Events) => option?.name}
                    getOptionValue={(option: Events) => option.id}
                    isOptionSelected={(
                      option: Events,
                      selectedValue: Events[]
                    ) => {
                      const isSelected = option?.id === selectedValue?.[0]?.id;
                      return isSelected;
                    }}
                    url={{
                      path: "events/ours"
                    }}
                    isSearchable
                  />

                  {/* <CFormSelect
                    name="eventId"
                    id="eventId"
                    className="custom-input"
                    required
                  >
                    <option selected value="">
                      Sélectionner l&apos;événement :
                    </option>
                    {events?.map((event: Events) => (
                      <option value={event?.id} key={event?.id}>
                        {event?.name}
                      </option>
                    ))}
                  </CFormSelect> */}
                </div>
              )}
              {offerType === OFFER_TYPES.SPECIAL && isAdmin && (
                <div className="col-md-6 mt-4">
                  <label htmlFor="placeId" className="d-block mb-2">
                    Place <span className="text-md text-red">*</span>
                  </label>
                  <FormSelectAPI
                    // @ts-ignore
                    name="placeId"
                    id="placeId"
                    isClearable={false}
                    getOptionLabel={(option: Place) => option?.name}
                    getOptionValue={(option: Place) => option.id}
                    isOptionSelected={(
                      option: Place,
                      selectedValue: Place[]
                    ) => {
                      const isSelected = option?.id === selectedValue?.[0]?.id;
                      return isSelected;
                    }}
                    url={{
                      path: "places"
                    }}
                    required
                    isSearchable
                    query={searchQuery}
                    onKeyDown={(event: any) => {
                      setSearchQuery(() => event?.nativeEvent?.target?.value);
                    }}
                  />
                  {/* <CFormSelect
                    name="placeId"
                    id="placeId"
                    className="custom-input"
                    required
                  >
                    <option selected value="">
                      Sélectionner un établissement
                    </option>
                    {Places?.map((place: Place) => (
                      <option value={place?.id} key={place?.id}>
                        {place?.name}
                      </option>
                    ))}
                  </CFormSelect> */}
                </div>
              )}

              <div className="col-md-6 mt-4">
                <label htmlFor="price" className="d-block">
                  Prix
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Prix"
                  id="price"
                  name="price"
                  disabled={offerType !== OFFER_TYPES.TOUR}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="reductionPercent" className="d-block">
                  Remise
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Remise"
                  id="reductionPercent"
                  name="reductionPercent"
                  disabled={offerType !== OFFER_TYPES.SPECIAL}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="numberOfPerson" className="d-block">
                  Nombre de personnes
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder=" Nombre de personnes"
                  id="numberOfPerson"
                  name="numberOfPerson"
                  disabled={offerType === OFFER_TYPES.EVENT}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="date" className="d-block">
                  Date
                </label>
                <CFormInput
                  type="date"
                  className="custom-input"
                  placeholder="Date"
                  id="date"
                  name="date"
                  disabled={offerType === OFFER_TYPES.EVENT}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="duration" className="d-block">
                  Durée de l&apos;offre
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Durée de l'offre"
                  id="duration"
                  name="duration"
                  disabled={offerType === OFFER_TYPES.EVENT}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="hour" className="d-block">
                  Heure
                </label>
                <CFormInput
                  type="time"
                  className="custom-input"
                  placeholder="Heure"
                  id="hour"
                  name="hour"
                  disabled={offerType === OFFER_TYPES.EVENT}
                />
              </div>
              {offerType !== "EVENT_OFFER" && (
                <div className="col-md-6 mt-4">
                  <label htmlFor="text" className="d-block">
                    Description de l&apos;offre{" "}
                    <span className="text-md text-red">*</span>
                  </label>

                  <RichTextEditor onChange={data => setDescription(data)} />
                </div>
              )}

              <div className="col-md-6 mt-4">
                <label htmlFor="picture" className="d-block">
                  Image de l&apos;offre
                </label>
                <CFormInput
                  type="file"
                  id="formFile"
                  name="picture"
                  className="custom-input"
                  disabled={offerType === OFFER_TYPES.EVENT}
                  accept="image/png,image/jpeg,image/jpg"
                />
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="carouselImage" className="d-block">
                  Image du carrousel <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="file"
                  id="carouselImage"
                  name="carouselImage"
                  className="custom-input"
                  required
                  accept="image/png,image/jpeg,image/jpg"
                />
              </div>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <Link
                  to="/offers"
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </Link>
                <button
                  className="btn btn-success green-shadow w-20 text-white"
                  type="submit"
                  disabled={createNewOfferMutation.isLoading}
                >
                  {createNewOfferMutation.isLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </CForm>
          </section>
        </BoxWrapper>
      </div>
    </>
  );
}
