import staticsLogos1 from "../../components/staticsLogos/1.png";
import staticsLogos2 from "../../components/staticsLogos/2.png";
import staticsLogos3 from "../../components/staticsLogos/3.png";
import staticsLogos4 from "../../components/staticsLogos/4.png";
import ProtectedRoute from "../auth/ProtectedRoute";

import { usePartnersQuery } from "../../hooks/Partners/usePartnersQuery";
import { usePlacesQuery } from "../../hooks/place/usePlacesQuery";
import { useUserQuery } from "../../hooks/users/useUserQuery";

import { Link } from "react-router-dom";

import HeaderMenu from "../../components/HeaderMenu";
import Sidebar from "../../components/Sidebar";
import { useAuthContext } from "../../context/auth";
import { formatLargeNumber } from "../../helpers/general";
import { useBookingQuery } from "../../hooks/bookings/useBookingQuery";

export default function AdminHome() {
  const { totalUsers } = useUserQuery();

  const { user } = useAuthContext();

  const { totalPlaces } = usePlacesQuery();

  const { Partners } = usePartnersQuery();

  const { totalCount } = useBookingQuery();

  return (
    <div>
      <h5 className="fs-5 mb-3 ps-5">Statistiques</h5>
      <div className="row row-cols-md-2 cart">
        <>
          <div className="border-0 position-relative carte">
            <Link to={"/users"}>
              <img src={staticsLogos2} alt="" className="w-100" />
            </Link>
            <div className="card-content">
              <p className="m-0 fs-2 text-white fw-bold">
                {formatLargeNumber(totalUsers)}
              </p>
              <p className="fs-4">Utilisateurs</p>
            </div>
          </div>

          <div className="border-0 position-relative carte pt-2">
            <Link to={"/places"}>
              <img src={staticsLogos1} alt="" className="w-100" />
            </Link>
            <div className="card-content">
              <p className="m-0 fs-2 text-white fw-bold">
                {formatLargeNumber(totalPlaces)}
              </p>
              <p className="fs-4">Établissement</p>
            </div>
          </div>

          <div className="border-0 position-relative carte">
            <Link to={"/partners"}>
              <img src={staticsLogos4} alt="" className="w-100" />
            </Link>
            <div className="card-content">
              <p className="m-0 fs-2 text-white fw-bold">
                {formatLargeNumber(Partners?.length)}
              </p>
              <p className="fs-4">Partenaires</p>
            </div>
          </div>
        </>
        <div className="border-0 position-relative carte">
          <Link to={"/bookings"}>
            <img src={staticsLogos3} alt="" className="w-100" />
          </Link>
          <div className="card-content">
            <p className="m-0 fs-2 text-white fw-bold">
              {formatLargeNumber(totalCount)}
            </p>
            <p className="fs-4">Réservations</p>
          </div>
        </div>
      </div>
    </div>
  );
}
