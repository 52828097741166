//@ts-nocheck
import { CFormCheck, CSpinner } from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import BoxWrapper from "../../../components/BoxWrapper";
import FileUploader from "../../../components/FileUploader";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";
import { FormMode } from "../../../helpers/enums";
import { useAuth } from "../../../hooks/useAuth";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import apiService from "../../../service/apiService";
import RestrictedRoute from "../../auth/RestrictedRoute";

const schema = yup.object().shape({
  relatedType: yup.string().required("Ce champ est obligatoire"),
  relationId: yup
    .string()
    .nullable()
    .when('relatedType', (relatedType, schema) => {
      if (!relatedType.includes('link')) {
        return schema.required("Ce champ est obligatoire");
      }
      return schema.nullable();
    }),
  image_large: yup.mixed().nullable().when('relatedType', (relatedType, schema) => {
    if (relatedType.includes('link')) {
      return schema.required("L'image de la publicité est obligatoire");
    }
    return schema.nullable();
  }),
  image_small: yup.mixed().nullable(),
  isActive: yup.boolean().required("Ce champ est obligatoire"),
  url: yup.string().when('relatedType', (relatedType, schema) => {
    if (relatedType.includes('link')) {
      return schema.url("URL invalide").required("Le lien est obligatoire");
    }
    return schema.nullable();
  })
});

export default function NewAdvertisement({ mode = FormMode.CREATE }) {
  const [formLoading, setFormLoading] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const navigate = useNavigate();
  const params = useParams();
  const [image_large, setImageLarge] = useState<File | null>(null);
  const [image_small, setImageSmall] = useState<File | null>(null);
  const { isAdmin } = useAuth();
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    watch: watchFormValue,
    setValue: setFormValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      relatedType: "place",

    },
  });

  console.log(errors)


  useEffect(() => {
    if (mode === FormMode.UPDATE && params?.id) {
      apiService.MakeGetRequest(`publications/${params.id}`, token).then(response => {
        setFormValue("relatedType", response.relatedType);
        const id = response.offer?.id || response.event?.id || response.place?.id;
        setFormValue("relationId", id);
        setImageLarge(response.image_large);
        setImageSmall(response.image_small);
        setFormValue("image_large", response.image_large);
        setFormValue("image_small", response.image_small);
        setFormValue("isActive", response.isActive);
        if (response.relatedType === "link") {
          setFormValue("url", response.url || "");
        }
      });
    }
  }, [params?.id]);

  const { data: publication, isLoading } = useQuery(
    ["publication", params.id],
    () => apiService.MakeGetRequest(`publications/${params.id}`, token),
    { enabled: mode === FormMode.UPDATE }
  );


  const createAdvertisementMutation = useMutation(async (data: FormData) => {
    return await apiService.MakePostRequest("publications", data, token, true);
  });

  const updateAdvertisementMutation = useMutation(async (data: FormData) => {
    return await apiService.MakePutRequest(`publications/${params.id}`, data, token, true);
  });

  const prepareFormData = (data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key !== "image_large" && key !== "image_small") {
        formData.append(key, data[key]);
      }
    });
    if (image_large) {
      formData.append("image_large", image_large);
    }
    if (image_small) {
      formData.append("image_small", image_small);
    }

    return formData;
  };


  const onSubmit = async (formData: any) => {
    const formattedData = prepareFormData(formData);

    if (mode === FormMode.UPDATE) {
      await handleUpdate(formattedData);

    } else {
      await handleCreate(formattedData);
      navigate(`/advertisement`);
    }
  };

  const handleCreate = async (data: FormData) => {
    try {
      await createAdvertisementMutation.mutateAsync(data);
      toast.success("Advertisement created successfully!");
    } catch (error) {
      toast.error("Failed to create advertisement.");
    }
  };

  const handleUpdate = async (data: FormData) => {
    try {
      await updateAdvertisementMutation.mutateAsync(data);
      toast.success("Advertisement updated successfully!");
      navigate(`/advertisement`);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Failed to update advertisement.");
      }
    }
  };



  return (
    <RestrictedRoute>
      <div className="mt-4">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>
                {mode === FormMode.UPDATE
                  ? "Modifier la publicité"
                  : "Ajouter une publicité"}
              </h4>
            </div>
            <form className=" row form  mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-6 mt-4 mb-4">
                <label htmlFor="target" className="d-block mb-1">
                  Cible de publicité <span className="text-md text-red">*</span>
                </label>
                <div className="d-flex gap-4 flex-wrap">
                  <Controller
                    name="relatedType"
                    control={formControl}
                    render={({ field }) => (
                      <CFormCheck
                        type="radio"
                        id="place"
                        label="places"
                        value="place"
                        checked={field.value === "place"}
                        onChange={() => {
                          field.onChange("place");
                          setSelectedItem(null);
                          // { mode === FormMode.CREATE && setFormValue("relationId", "") };
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="relatedType"
                    control={formControl}
                    render={({ field }) => (
                      <CFormCheck
                        type="radio"
                        id="offer"
                        label="offres"
                        value="offer"
                        checked={field.value === "offer"}
                        onChange={() => {
                          field.onChange("offer");
                          setSelectedItem(null);
                          // { mode === FormMode.CREATE && setFormValue("relationId", "") };
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="relatedType"
                    control={formControl}
                    render={({ field }) => (
                      <CFormCheck
                        type="radio"
                        id="event"
                        label="Evénements"
                        value="event"
                        checked={field.value === "event"}
                        onChange={() => {
                          field.onChange("event");
                          setSelectedItem(null);
                          //  { mode === FormMode.CREATE && setFormValue("relationId", ""); }
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="relatedType"
                    control={formControl}
                    render={({ field }) => (
                      <CFormCheck
                        type="radio"
                        id="link"
                        label="Lien"
                        value="link"
                        checked={field.value === "link"}
                        onChange={() => field.onChange("link")}
                      />
                    )}
                  />
                </div>
                {errors.relatedType?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.relatedType.message}
                  </div>
                )}
              </div>

              {watchFormValue("relatedType") === "link" && (
                <div className="col-md-6 mt-4 w-full">
                  <label htmlFor="linkUrl" className="d-block mb-1">
                    URL de la publicité <span className="text-md text-red">*</span>
                  </label>
                  <input
                    type="text"
                    id="linkUrl"
                    className="form-control"
                    {...register("url")}
                  // value={url}
                  />
                  {errors.url?.message && (
                    <div className="text-red-500 text-opacity-50" style={{ color: "red" }}>
                      {errors.url.message}
                    </div>
                  )}
                </div>
              )}

              {watchFormValue("relatedType") !== "link" && (
                <Controller
                  name="relationId"
                  control={formControl}
                  // defaultValue={defaultValue?.id}
                  render={({ field }) => {
                    const offerEndpoint = !isAdmin
                      ? "specials/ours"
                      : "specials/all";


                    const defaultLabel =
                      watchFormValue("relatedType") === "event"
                        ? publication?.event?.name
                        : watchFormValue("relatedType") === "offer"
                          ? publication?.offer?.title
                          : publication?.place?.name;

                    return (
                      <InfiniteScrollSelect
                        id="relationId"
                        error={errors?.relationId?.message}
                        getOptionLabel={option => option?.name || option?.title}
                        getOptionValue={option => option.id}
                        isOptionSelected={(option, selectedValue) =>
                          option?.id === selectedValue?.[0]?.id
                        }
                        url={{
                          path:
                            watchFormValue("relatedType") === "event"
                              ? "events/ours"
                              : watchFormValue("relatedType") === "offer"
                                ? offerEndpoint
                                : "places"
                        }}
                        value={
                          selectedItem ||
                          (defaultLabel && {
                            id: field.value,
                            name: defaultLabel
                          }) || null
                        }
                        onChange={(item: any) => {
                          setSelectedItem(item);
                          field.onChange(item?.id || "");
                        }}
                      />
                    );
                  }}
                />
              )}
              {errors.relationId?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.relationId.message}
                </div>
              )}

              <div className="col-md-6 mt-4">
                <label htmlFor="carouselImage" className="d-block mb-1">
                  Image du large screens
                </label>

                <FileUploader
                  type="file"
                  id="image_large"
                  //  name="image_large"
                  file={image_large}
                  accept="image/png,image/jpeg,image/jpg"
                  onChange={e => {
                    const file = e.target.files?.[0];
                    if (file) {
                      setImageLarge(file);
                      setFormValue("image_large", file);
                    }
                  }}


                />
                {errors.image_large?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.image_large.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="carouselImage" className="d-block mb-1">
                  Image du small screens
                </label>

                <FileUploader
                  type="file"
                  id="image_small"
                  //  name="image_large"
                  file={image_small}
                  accept="image/png,image/jpeg,image/jpg"

                  onChange={e => {
                    const file = e.target.files?.[0];
                    if (file) {
                      setImageSmall(file);
                      setFormValue("image_small", file);
                    }
                  }}

                />
                {errors.image_small?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.image_small?.message}
                  </div>
                )}
              </div>


              <div className="col-md-6 mt-4">
                <CFormCheck
                  type="checkbox"
                  id="isActive"
                  label="Activer la publicité"
                  {...register("isActive")}
                />
              </div>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <Link
                  to="/advertisement"
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </Link>
                <button
                  className="btn btn-success shadow-secondary w-20 text-white"
                  type="submit"
                  disabled={formLoading}
                >
                  {formLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </RestrictedRoute>
  );
}
