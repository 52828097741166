import {
  CModal,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import DeleteIcon from "../../components/icons/DeleteIcon";
import EditIcon from "../../components/icons/EditIcon";
import PromptModal from "../../components/modals/PromptModal";
import { colors } from "../../config/theme";
import { useCookie } from "../../hooks/useCookie";
import apiService from "../../service/apiService";

// Define the Publication interface with nested event and place objects


interface TableProps {
  publications: Publication[];
  refetch: () => void;
}





// Table row component
function TableBodyRowContent({
  publication,
  refetch,
}: {
  publication: Publication;
  refetch: () => void;
}) {

  const [showModal, setShowModal] = useState(false);

  const { token } = useCookie("vToken");

  const deleteItemMutation = useMutation(
    (id: string) => apiService.MakeDeleteRequest(`publications/${id}`, token),
    {
      onSuccess: () => {
        toast.success("Item deleted successfully!");
        refetch();

      },
      onError: (error) => {
        toast.error("Error deleting item: ");
      },
    }
  );

  const handleDelete = async (selectedPublicationId: string) => {
    if (selectedPublicationId) {
      try {
        await deleteItemMutation.mutateAsync(selectedPublicationId);
      } finally {
        setShowModal(false);
      }
    }
  };


  return (
    <CTableRow style={{ width: "100%", position: "relative" }}>
      <CTableDataCell>{new Date(publication.createdAt).toLocaleDateString()}</CTableDataCell>
      <CTableDataCell>{publication.relatedType}</CTableDataCell>
      <CTableDataCell
        style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        className="truncate text-blue-500 underline"
      >
        {publication.event ? (
          publication.event.name
        ) : publication.offer ? (
          publication.offer.title
        ) : publication.place ? (
          publication.place.name
        ) : (
          <a href={publication.url} target="_blank" rel="noopener noreferrer">
            {publication.url}
          </a>
        )}
      </CTableDataCell>

      <CTableDataCell>{publication.isActive ? 'Yes' : 'No'}</CTableDataCell>
      <CTableDataCell>
        <div className="d-flex align-items-center mt-2">
          <Link to={`/advertisement/update/${publication?.id}`} key={publication?.id}>
            <EditIcon width="18" height="18" fill={colors.secondary} />
          </Link>{" "}
          <button
            onClick={() => setShowModal(true)}
            className="outline-none border-0 bg-transparent"
          >
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          </button>

        </div>
      </CTableDataCell>
      <PromptModal
        onClose={() => setShowModal(false)}
        onAccept={() => handleDelete(publication.id)}
        open={showModal}
      />

    </CTableRow>
  );
}


export default function AdvertisementTable({ publications, refetch }: TableProps) {
  const [selectedPublication, setSelectedPublication] = useState<Publication | null>(null);
  const [visible, setVisible] = useState(false);

  return (
    <div className="mt-3">
      <CModal visible={visible} onClose={() => setVisible(false)}>
        {selectedPublication && (
          <div className="p-4">
            <h5>Publication Details</h5>
            <p><strong>Date:</strong> {selectedPublication.id}</p>
            <p><strong>Categorie:</strong> {selectedPublication.relatedType}</p>
            <p><strong>Is Active:</strong> {selectedPublication.isActive ? 'Yes' : 'No'}</p>
            <p className="w-[2rem]" ><strong>Nom:</strong> {selectedPublication.event ? selectedPublication.event.name : selectedPublication.offer ? selectedPublication.offer?.title : selectedPublication.url}</p>

          </div>
        )}
      </CModal>

      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Date</CTableHeaderCell>
            <CTableHeaderCell scope="col">Categorie</CTableHeaderCell>
            <CTableHeaderCell scope="col">Nom</CTableHeaderCell>
            <CTableHeaderCell scope="col">Is Active</CTableHeaderCell>
            <CTableHeaderCell scope="col">Modifier</CTableHeaderCell>

          </CTableRow>
        </CTableHead>
        <CTableBody>
          {publications?.map((publication) => (
            <TableBodyRowContent
              key={publication.id}
              publication={publication}
              // viewDetails={() => {
              //   setSelectedPublication(publication);
              //   setVisible(true);
              // }}
              refetch={refetch}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}

