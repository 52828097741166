import {
  CFormCheck,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import EditIcon from "../../components/icons/EditIcon";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { useEventQuery } from "../../hooks/events/useEventQuery";
import { DELETE_ACTIONS } from "../../config/constants";
import PromptModal from "../../components/modals/PromptModal";
import { Link } from "react-router-dom";
import TranslateIcon from "../../components/icons/TranslateIcon";
import { useAuth } from "../../hooks/useAuth";

function TableBodyRowContent({
  event,
  deleteList
}: {
  event: Events;
  deleteList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { isAdmin } = useAuth();

  const { removeEvent } = useEventQuery();

  useEffect(() => {
    if (isChecked) {
      deleteList({ type: DELETE_ACTIONS.ADD, id: event?.id });
    } else {
      deleteList({ type: DELETE_ACTIONS.REMOVE, id: event?.id });
    }
  }, [deleteList, isChecked, event?.id]);

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="px-3">
        <div className="d-flex align-items-center gap-3">
          <CFormCheck
            id="flexCheckDefault"
            label=""
            defaultChecked={isChecked}
            onChange={e => setIsChecked(e.target.checked)}
            color={colors.yellow}
          />
          <img
            src={event?.image}
            alt=""
            width={30}
            height={30}
            style={{ borderRadius: "100%" }}
          />

          <div>
            <Link
              to={`/events/${event?.id}`}
              className="fw-bold m-0 text-capitalize text-sm"
            >
              {event?.name}
            </Link>
            <p className="m-0 text-sm line-clamp-1" style={{ width: "95%" }}>
              {event?.address}
            </p>
          </div>
        </div>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">{event?.phone}</CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {event?.pageVisits}
      </CTableDataCell>
      <CTableDataCell className="p-3">
        <Link to={`/events/${event?.id}/services`}>
          <ArrowTopRightOnSquareIcon className="me-1" width="20" height="20" />
        </Link>
      </CTableDataCell>
      <CTableDataCell className="p-3">
        <Link to={`/events/${event?.id}/reservation`}>
          <ArrowTopRightOnSquareIcon className="me-1" width="20" height="20" />
        </Link>
      </CTableDataCell>
      <CTableDataCell className="p-3">
        <Link to={`/promo-codes/${event?.id}/event`}>
          <ArrowTopRightOnSquareIcon className="me-1" width="20" height="20" />
        </Link>
      </CTableDataCell>
      <CTableDataCell className="p-3">
        {Number.isFinite(event?.venezPercentage) ? (
          <Link to={`/events/${event?.id}/statistics`}>
            <ArrowTopRightOnSquareIcon
              className="me-1"
              width="20"
              height="20"
            />
          </Link>
        ) : null}
      </CTableDataCell>

      <CTableDataCell>
        <div className="d-flex align-items-center mt-2">
          <Link to={`/events/update/${event.id}`} key={event.id}>
            <EditIcon width="18" height="18" fill={colors.secondary} />
          </Link>{" "}
          <button
            onClick={() => setShowModal(true)}
            className="outline-none border-0 bg-transparent"
          >
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          </button>
          {isAdmin && (
            <Link
              to={"/event-translations/new"}
              state={{ event: event }}
              className="ms-4"
            >
              <TranslateIcon width="21" height="21" fill={"#000"} />
            </Link>
          )}
        </div>
      </CTableDataCell>
      <PromptModal
        onClose={() => setShowModal(false)}
        onAccept={() => removeEvent(event?.id)}
        open={showModal}
      />
    </CTableRow>
  );
}
interface TableProps {
  events: Events[];
  eventsToBeDeletedList: React.Dispatch<BulkDeleteActions>;
}
export default function EventsTable({
  eventsToBeDeletedList,
  events
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-sm w-50">
              Nom
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Téléphone
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Visites de page
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Services
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              réservation
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Codes promo
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Statistiques
            </CTableHeaderCell>
            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {events?.map(event => (
            <TableBodyRowContent
              key={event?.id}
              event={event}
              deleteList={eventsToBeDeletedList}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}
