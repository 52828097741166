import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Icon, { LocationIcon } from "../../components/icons";
import apiService from "../../service/apiService";
import Tickets from "./Tickets";

export default function Event() {
  const params = useParams();

  const { data } = useQuery<Events>(["getEvent"], () => {
    return apiService.MakeGetRequest(`events/${params?.eventId}`);
  });

  return (
    <main>
      <section className="w-85 mx-auto my-7">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h6 className="fw-light mb-0 text-uppercase text-md text-[#8F90A6]">
              {data?.type?.label}
            </h6>
            <h4 className="fs-1 fw-semibold text-[#1F2937] mb-2 lg:text-6xl text-capitalize">
              {data?.name}
            </h4>
            <p className="text-danger text-sm lg:text-md">
              À PARTIR DE {data?.startingHour}H
            </p>
          </div>
          <div className="bg-gradient-to-r from-orange-primary to-yellow-primary rounded-lg w-20 h-20 d-flex flex-col justify-content-center">
            <p className="fw-bold text-uppercase text-xl text-center text-white"></p>
            <p className="text-center text-white text-md fw-bold text-uppercase"></p>
          </div>
        </div>
        <div className="py-5 border-top border-bottom my-5">
          <h6 className="fw-semibold mb-4 ">À Propos</h6>
          <p
            className="text-gray-500 fw-light mb-0 whitespace-pre-wrap firstletter "
            dangerouslySetInnerHTML={{ __html: data?.description as string }}
          />
          <div className="mt-5 gap-y-8 row">
            <div className="col-md-6">
              <h5 className="fw-semibold mb-4 text-md">
                Emplacement et coordonnées
              </h5>
              <div className="d-flex align-items-center gap-2 mb-1">
                <div className="">
                  {/* <Icon name="location" width={20} height={20} /> */}
                  <LocationIcon width="20" height="20" />
                </div>
                <p className="fw-light mb-0 text-sm sm:w-[55%]">
                  {data?.address}
                </p>
              </div>
              <div className="d-flex align-items-center gap-2 mt-3 mb-1 ">
                <div className="">
                  <Icon name="clock" width={20} height={20} />
                </div>
                <p className="fw-light mb-0 text-sm sm:w-[55%]">{`${data?.startingHour}H - ${data?.endingHour}`}</p>
              </div>
              <div className="d-flex align-items-center gap-2 mt-3 mb-1">
                <div className="">
                  <Icon name="phone" width={20} height={20} />
                </div>
                <p className="fw-light mb-0 text-sm sm:w-[55%]">
                  {data?.phone}
                </p>
              </div>
              <div className="d-flex align-items-center gap-2 mt-2 mb-1">
                <div className="mt-1">
                  <Icon name="envelop" width={20} height={20} />
                </div>
                <a href={`mailto:${data?.email}`}>
                  <p className="fw-light mb-0 text-sm sm:w-[55%] text-body">
                    {data?.email}
                  </p>
                </a>
              </div>

              {data?.website && (
                <div className="d-flex align-items-center gap-2 mt-2 mb-1">
                  <div className="mt-1">
                    <Icon name="web" width={20} height={20} />
                  </div>
                  <a
                    href={data?.website}
                    target="__blank"
                    className="fw-light mb-0 text-sm sm:w-[55%] text-body"
                  >
                    {data?.website.replace(/^https?:\/\//i, "")}
                  </a>
                </div>
              )}
              {data?.instagramUrl && (
                <div className="d-flex align-items-center gap-2 mt-2 mb-1">
                  <div className="mt-1">
                    <Icon name="instagram" width={20} height={20} />
                  </div>
                  <a
                    href={data?.instagramUrl}
                    target="__blank"
                    className="fw-light mb-0 text-sm sm:w-[55%] text-body"
                  >
                    @{" "}
                    {data?.instagramUrl.replace(
                      /^https:\/\/www\.instagram\.com\//,
                      ""
                    )}
                  </a>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <h5 className="fw-semibold mb-4 text-md">
                Modes d&apos;obtention des billets
              </h5>
              <p className="fw-light mb-0 mb-2 text-gray-400">
                <span className="text-danger fw-semibold">E-ticket: </span>
                Imprimez vos billets chez vous dès la fin de votre commande et
                recevez-les également par email en format pdf.
              </p>
            </div>
          </div>
        </div>

        {data?.services?.map(service => (
          <Tickets key={service.id} service={service} />
        ))}
      </section>
    </main>
  );
}
