import { CModal, CModalContent } from "@coreui/react";
import React, { MouseEvent } from "react";
import { useToast } from "../../hooks/useToast";
import { QRCodeSVG } from "qrcode.react";
import html2canvas from "html2canvas";
import { useScreenshot, createFileName } from "use-react-screenshot";
import { useSvgScreenShoot } from "../../hooks/use-svg-screenshoot";

interface Props {
  visible: boolean;
  onClose: () => void;
  slug: string;
  category: string;
  name: string;
}
export default function QrCodeModal(props: Props) {
  const { visible, onClose, slug, category, name: qrCodeName } = props;
  const link = `${process.env.REACT_APP_FRONTEND_URL}/${slug}`;
  const { toastSuccess } = useToast();
  const qrCode = React.createRef<HTMLDivElement>();

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 10.0
  });

  const { ref, downloadSVG } = useSvgScreenShoot();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(() => toastSuccess("Lien copié!"));
  };

  const downloadImage = (
    image: any,
    { name = qrCodeName, extension = "jpg" } = {}
  ) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadQrCode = async (e: MouseEvent<HTMLButtonElement>) => {
    takeScreenShot(qrCode.current).then(downloadImage);
  };

  return (
    <CModal visible={visible} onClose={onClose}>
      <CModalContent className="py-3">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div id="qr-code" className="p-3">
            <h3 className="text-center">Scannez le code QR</h3>
            <p className="text-light text-center w-75 mx-auto">
              Scanner ce code pour réserver cet établissement
            </p>
            <div className="d-flex justify-content-center" ref={ref}>
              <div
                className="my-2 rounded-sm border p-2 position-relative"
                ref={qrCode}
              >
                <QRCodeSVG
                  value={link}
                  fgColor="#000000"
                  level="H"
                  size={190}
                />
                <img src="/venez_logo.png" alt="" className="brand-photo" />
              </div>
            </div>
          </div>

          <div className="d-flex flex-column align-items-center w-75">
            <p className="text-light scan-alternative text-center w-100">
              Ou copiez le lien
            </p>
            <div className="d-flex align-items-center gap-3 mb-4 w-100">
              <label
                className="p-2 rounded-sm border line-clamp-1 text-sm d-block"
                // style={{ width: "450.5px" }}
              >
                {link}
              </label>
              <button
                onClick={copyToClipboard}
                className=" rounded-sm border bg-transparent outline-none d-flex align-items-center justify-content-center py-1 hover-light"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                  />
                </svg>
              </button>
            </div>
            <div className="d-flex align-items-center">
              {/* Télécharger{" "} */}
              <button
                onClick={downloadQrCode}
                className="bg-danger shadow-primary p-2 rounded-sm text-white text-center border-0 me-3"
              >
                Télécharger Png
              </button>
              <button
                onClick={() => downloadSVG(slug)}
                className="bg-danger shadow-primary p-2 rounded-sm text-white text-center border-0"
              >
                Télécharger Svg
              </button>
            </div>
          </div>
        </div>
      </CModalContent>
    </CModal>
  );
}
